<template>
  <div mt-5>
    <div class='flex flex-row my-1'>
      <h4>Refixing 정보</h4>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-refixing-data-form-btn'
        @click='addNewRefixing'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>

    <table class='mt-4 text-sm'>
      <thead>
        <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
          <th class='px-2 py-2 text-center border-l border-r'>리픽싱일자</th>
          <th class='px-2 py-2 text-center border-r'>전환가</th>
          <th class='px-2 py-2 text-center border-r'>하단</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(refix, index) in refixings'
          :key='`refix-${refix.id}-${index}`'>
          <td class='px-2 text-left border-l border-r'>
            <input type='text' v-model='refix.refixing_date' class='text-base text-left px-4 w-32'
              @paste.prevent='onPasteRefixing($event, index)'
            />
          </td>
          <td class='px-2 border-r'>
            <input type='text' v-model='refix.before_convertible_value' class='text-base text-right px-4 w-24' />
          </td>
          <td class='px-2 border-r'>
            <input type='text' v-model='refix.after_convertible_value' class='text-base text-right px-4 w-24' />
          </td>
        </tr>
      </tbody>
      <tfoot><tr><td colspan='100' class='text-right py-4'>
        <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
          @click='uploadRefixing'>Save Changes</button></td></tr>
      </tfoot>
    </table>
  </div>
</template>


<script>
import { mapActions, mapState, mapGetters }  from 'vuex'
import { PlusCircleIcon }                    from '@vue-hero-icons/outline'
import cloneDeep                             from 'lodash/cloneDeep'
import optionSchedulesApi                    from '@/api/v1/option_schedules'
import CopyPasteHelpers                      from '@/utils/copy-paste-helpers'


export default {
  name: 'Refixnings',
  components: {
    PlusCircleIcon,
  },
  data () {
    return {
      refixings: [],
    }
  },
  watch: {
    'drilldownListingId': {
      handler: function () {
        if (this.drilldownListingId > 0) {
          this.getRefixings ()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('listings', [
      'refixingSchedules',
    ]),
    ...mapGetters('listings', [
      'drilldownListingId',
      'drilldownListingShareCode',
    ]),
  },
  methods: {
    ...mapActions('listings', [
      'getRefixingSchedules',
    ]),
    addNewRefixing () {
      this.refixings.push({id: 0})
    },
    getRefixings () {
      this.getRefixingSchedules ({ share_code: this.drilldownListingShareCode }).then( () => {
        this.refixings = cloneDeep(this.refixingSchedules)
      })
    },
    uploadRefixing () {
      if (this.drilldownListingId > 0) {
        optionSchedulesApi.postRefixingSchedule({ share_code: this.drilldownListingShareCode, refixings: this.refixings }).then(resp => {
          this.getRefixings ()
          console.log(resp)
        })
      } else {
        alert('자산코드를 먼저 선택하세요')
      }
    },
    onPasteRefixing (data, refixIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      var remainLength = this.refixings.length - refixIndex

      var ii
      for (ii = 0; ii <= pastedArray.length - remainLength; ii++) {
        this.refixings.push({id: 0})
      }

      pastedArray.forEach((row, index) => {
        this.updateRefixingRow(index + refixIndex, row)
      })

      if (ii > 0) {
        this.refixings.pop()
      }
    },
    updateRefixingRow (rowIndex, dataArray) {
      var index = 0

      this.refixings[rowIndex].refixing_date = dataArray[index++]
      this.refixings[rowIndex].before_convertible_value = dataArray[index++]
      this.refixings[rowIndex].after_convertible_value = dataArray[index++]
    },
  },
}
</script>
