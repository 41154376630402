<template>
  <div>
    <ipo-search class='py-2' />
    <div v-if='hasSelectedIpo'>
      <ipo-market-data />
      <ipo-investment-schedule />
      <ipo-investment-management />
      <ipo-result-transfer />
      <ipo-result-funds />
      <ipo-result-accounts />
      <ipo-result-funds-accounts />
      <ipo-result-lockup />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IpoSearch               from '@/components/IpoSearch.vue'
import IpoMarketData           from '@/views/ipo/IpoMarketData.vue'
import IpoInvestmentSchedule   from '@/views/ipo/IpoInvestmentSchedule.vue'
import IpoInvestmentManagement from '@/views/ipo/IpoInvestmentManagement.vue'
import IpoResultTransfer       from '@/views/ipo/IpoResultTransfer.vue'
import IpoResultFunds          from '@/views/ipo/IpoResultFunds.vue'
import IpoResultAccounts       from '@/views/ipo/IpoResultAccounts.vue'
import IpoResultFundsAccounts  from '@/views/ipo/IpoResultFundsAccounts.vue'
import IpoResultLockup         from '@/views/ipo/IpoResultLockup.vue'

export default {
  name: 'IpoPublicOffering',
  components: {
    IpoSearch,
    IpoMarketData,
    IpoInvestmentSchedule,
    IpoInvestmentManagement,
    IpoResultTransfer,
    IpoResultFunds,
    IpoResultAccounts,
    IpoResultFundsAccounts,
    IpoResultLockup,
  },
  computed: {
    ...mapGetters('ipos', [
      'hasSelectedIpo',
    ]),
  },
}
</script>
