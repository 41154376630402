<template>
  <div class='bg-white'>
    <ipo-allocated-lists />
    <div v-if='hasSelectedIpo'
      class='flex flex-col lg:flex-row justify-start items-start relative overflow-x-auto'>
      <ipo-allocated-information />
      <ipo-allocated-summaries />
    </div>
    <div v-if='hasSelectedIpo'
      class='flex flex-col mt-2 lg:flex-row gap-x-2 justify-start items-start relative overflow-x-auto'>
      <button class='w-40 lg:w-48 py-3 flex flex-row gap-x-1 items-center' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4' />
        <h3 class=''>기안(닥스웨이브)</h3>
      </button>
      <div class='flex flex-row gap-x-4'>
        <ipo-docs-wave v-if='showDetails' class='' />
        <ipo-docs-wave-summary v-if='showDetails' class='' />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'
import IpoAllocatedLists       from '@/views/ipo/IpoAllocatedLists.vue'
import IpoAllocatedInformation from '@/views/ipo/IpoAllocatedInformation.vue'
import IpoAllocatedSummaries   from '@/views/ipo/IpoAllocatedSummaries.vue'
import IpoDocsWave             from '@/views/ipo/IpoDocsWave.vue'
import IpoDocsWaveSummary      from '@/views/ipo/IpoDocsWaveSummary.vue'
import base                    from '@/api/v1/base.js'

export default {
  name: 'IpoAllocated',
  components: {
    IpoAllocatedLists,
    IpoAllocatedInformation,
    IpoAllocatedSummaries,
    IpoDocsWave,
    IpoDocsWaveSummary,
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapGetters('ipos', [
      'hasSelectedIpo',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
    ]),
    ...mapActions('brokers', [
      'getBrokers',
    ]),
    refreshData () {
      this.getIpos()
      this.getBrokers()
    },
    setAuthToken (token) {
      base.defaults.headers.common['Authorization'] = ''
      delete base.defaults.headers.common['Authorization']
      base.defaults.headers.common['Authorization'] = token
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
  },
  mounted () {
    if (this.$route.query.l_s_keyname && this.$route.query.l_s_keyvalue) {
      console.log('router query params:', this.$route.query.l_s_keyname, this.$route.query.l_s_keyvalue)
      console.log('AXIOS token: ', base.defaults.headers.common['Authorization'])
      localStorage.setItem(this.$route.query.l_s_keyname, this.$route.query.l_s_keyvalue)
      this.setAuthToken(this.$route.query.l_s_keyvalue)
    } else if (localStorage.getItem('access_token.bbridge_reach')) {
      this.setAuthToken(localStorage.getItem('access_token.bbridge_reach'))
      console.log('AXIOS token: ', base.defaults.headers.common['Authorization'])
    } else {
      console.log('no token')
      console.log('access_token.bbridge_reach:', localStorage.getItem('access_token.bbridge_reach'))
      console.log('AXIOS token: ', base.defaults.headers.common['Authorization'])
      console.log('router query params:', this.$route.query.l_s_keyname, this.$route.query.l_s_keyvalue)
    }
    this.refreshData()
  }
}
</script>
