<template>
  <div class='px-2 lg:px-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>기업</h1>
      </div>
    </portal>
    <div class='flex flex-row justify-start items-center gap-x-4 my-6'>
      <button
        @click='addEntity'        
        class='border p-3 rounded-md border-gray-50 text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center'>
        <plus-circle-icon class='h-4'/>
        Add
      </button>
      <entity-filters :entityName='initialEntityName()' />
    </div>
    <table class='text-sm'>
      <thead>
        <tr>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>기업코드</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>기업명</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>법인구분</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>법인번호</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>사업자등록번호</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>대표이사</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap'>섹터 대분류</th>
        </tr>
      </thead>
      <tbody>
        <entities-row-investment-mode
          v-for='entity in entities'
          :key='`entity-table-row-${entity.id}`'
          :entity='entity'
          :class='selectedClass(entity.id)'
          class='border-t border-b hover:bg-gray-200 cursor-pointer'
          @dblclick.native='ifComponetEntityDetailsOpen(entity)'
          @click.native='ifNotComponetEntityDetailsOpen(entity)' />
      </tbody>
    </table>
    <portal to='modals'>
      <entity-data-edit v-if='addNewEntity'
        :display-in-modal='true'
        class='px-16'
        @done-editing='doneEditing' />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import EntityDataEdit            from '@/views/entities/EntityDataEdit.vue'
import EntitiesRowInvestmentMode from '@/views/entities/EntitiesRowInvestmentMode.vue'
import EntityFilters             from '@/views/entities/EntityFilters.vue'
import LedgerCodeApi             from '@/api/v1/ledger_codes'

export default {
  name: 'Entities',
  components: {
    PlusCircleIcon,
    EntityDataEdit,
    EntityFilters,
    EntitiesRowInvestmentMode,
  },
  data () {
    return {
      addNewEntity: false,
      newEntityName: '',
    }
  },
  computed: {
    ...mapState('entities', [
      'entities',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityId',
    ]),
  },
  methods: {
    ...mapActions('entities', [
      'entityDetailsOpen',
      'createEntityWithName',
      'onlySetDrilldownEntity',
      'setSectorCodes',
    ]),
    addEntity () {
      this.$store.dispatch('modalOpen', {height: 60, width: 60})
      this.addNewEntity = true
    },
    doneEditing () {
      this.addNewEntity = false
      this.$store.dispatch('modalClose')
    },
    selectedClass (entityId) {
      return (this.drilldownEntityId === entityId) ? 'bg-blue-100 font-semibold' : ''
    },
    initialEntityName () {
      return this.useComponent ? '00000' : ''
    },
    ifNotComponetEntityDetailsOpen (entity) {
      if (!this.useComponent) {
        this.entityDetailsOpen (entity)
      } else {
        this.onlySetDrilldownEntity(entity)
      }
    },
    ifComponetEntityDetailsOpen (entity) {
      if (this.useComponent) {
        this.entityDetailsOpen (entity)
      }
    },
  },
  mounted () {
    LedgerCodeApi.getLedgerCodes({search_type: 'sector_large'}).then(resp => {
      this.setSectorCodes(resp)
    })
  },
}
</script>
