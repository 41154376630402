import base from './base'

const getPortfolioSectors = (filter) => base
  .get('/portfolio_sectors', {params: filter}).then(resp => resp.data)

const patchPortfolioSector = (portfolioSector) => base
  .patch(`/portfolio_sectors/${portfolioSector.tms_investment_id}`, portfolioSector).then(resp => resp.data)

export default {
  getPortfolioSectors: getPortfolioSectors,
  patchPortfolioSector: patchPortfolioSector,
}
