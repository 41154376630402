<template>
  <div class='pb-8 pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>자산정보관리</h1>
      </div>
    </portal>
    <div class='flex flex-row items-center py-8 w-full overflow-x-auto'>
      <label class='block mr-4 whitespace-no-wrap'>기준일자</label>
        <el-date-picker
          v-model='selectedDate'
          type='date'
          value-format='yyyy-MM-dd'
          placeholder='기준일자'
          class='w-64 mr-5'
          @change='refreshData'>
        </el-date-picker>
        <button class='border p-3 flex flex-row items-center rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md' @click='receiveData'>
          <database-icon class='inline-block' />
          <span class='inline-block ml-1'>Receive</span>
        </button>
        <button
          class='border p-3 flex flex-row items-center rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='addDailyPerformances'>
          <plus-circle-icon class='inline-block' />
          <span class='inline-block ml-1 whitespace-no-wrap'>Add HighYield</span>
        </button>
        <button
          v-if='isAddDailyPerformances'
          class='ml-2 border p-3 flex flex-row items-center rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='patchDailyPerformances'>
          <save-icon class='inline-block' />
          <span class='inline-block ml-1'>Save</span>
        </button>
        <button
          v-if='isAddDailyPerformances'
          class='border p-3 flex flex-row items-center rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='refreshData'>
          <x-icon class='inline-block' />
          <span class='inline-block ml-1'>Cancel</span>
        </button>
    </div>
    <el-radio-group v-model='accountType' size='large'>
      <el-radio-button label='전체'></el-radio-button>
      <template v-for='type in accountTypes'>
        <el-radio-button :label='type' :key='type'></el-radio-button>
      </template>
    </el-radio-group>
    <div class='py-4 overflow-auto'>
      <table class='text-sm table-fixed'>
        <thead>
          <tr>
            <th class='border-r border-t' colspan='5'>기본정보</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='border-r border-t' colspan='9'>자산정보</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='border-r border-t' colspan='9'>벤처펀드 현황</th>
            <th v-if='isViewHighYieldInfo' class='border-t' colspan='11'>하이일드펀드 현황</th>
          </tr>
          <tr>
            <th class='text-left border-r'>기준일자</th>
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.fund_code'
              keyName='fund_code'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='펀드코드'
              :optionObject='dailyPerformances'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.fund_name'
              keyName='fund_name'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='펀드명'
              :optionObject='dailyPerformances'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.fund_short_name'
              keyName='fund_short_name'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='펀드약칭'
              :optionObject='dailyPerformances'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.fund_target_asset'
              keyName='fund_target_asset'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='투자구분'
              :optionObject='dailyPerformances'
              class='px-2 py-2 text-left border-r border-l' />
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>자산총액</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>순자산총액</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>좌수</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>과세순자산총액</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>비거주자순자산총액</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>원본액</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>기준가</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>과표기준가</th>
            <th v-if='!isAddDailyPerformances && isViewAssetInfo' class='text-right border-r'>비거주자과표기준가</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-left border-r'>벤처구간시작</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-left border-r'>벤처구간종료</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>벤처기업평가액(신주)</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>벤처기업평가액(신주제외)</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>자산총액</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>NAV</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>신주 보유비율(%)</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>신주제외 보유비율(%)</th>
            <th v-if='!isAddDailyPerformances && isViewVentureInfo' class='text-right border-r'>벤처기업 총 보유비율(%)</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>NAV</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>비우량채권 + 코넥스주식</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>비우량채권 + 코넥스주식 비중</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>코넥스</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>코넥스 비중</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>비우량 이외 채권</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>비우량 이외 채권 비중</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>전체채권</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>전체채권 비중</th>
            <th v-if='isViewHighYieldInfo' class='text-right border-r'>전체채권 + 코넥스 합계</th>
            <th v-if='isViewHighYieldInfo' class='text-right'>전체채권 + 코넥스 비중</th>
          </tr>
        </thead>
        <tbody v-if='!isAddDailyPerformances || hasDailyPerformances'>
          <tr v-if='isViewSubTotal' class='text-base text-blue-700 cursor-pointer font-medium bg-yellow-200'>
            <td class='py-3 pl-2 pr-6 border-r'></td>
            <td class='py-3 pl-2 pr-6 border-r'></td>
            <td class='py-3 pl-2 pr-6 border-r'></td>
            <td class='py-3 pl-2 pr-6 border-r'></td>
            <td class='py-3 pl-2 pr-6 border-r'>합계</td>
            <td class='py-3 pl-2 pr-6 border-r'></td>
            <td class='py-3 pl-2 pr-6 border-r'></td>
            <td class='py-3 pl-2 pr-6 text-right border-r'>{{numberStyle(totalVentureNewSharesCloseValue)}}</td>
            <td class='py-3 pl-2 pr-6 text-right border-r'>{{numberStyle(totalVentureExcludeNewSharesCloseValue)}}</td>
            <td class='py-3 pl-2 pr-6 text-right border-r'>{{numberStyle(totalVentureTotalAssetsAmount)}}</td>
            <td class='py-3 pl-2 pr-6 text-right border-r'>{{numberStyle(totalVentureNavAmount)}}</td>
            <td class='py-3 pl-2 pr-6 text-right border-r'></td>
            <td class='py-3 pl-2 pr-6 text-right border-r'></td>
            <td class='py-3 pl-2 pr-6 text-right border-r'></td>
          </tr>
          <daily-performance-row
            v-for='dailyPerformance in filteredData'
            :key='`daily-performance-row-${dailyPerformance.fund_id}`'
            :is-show='!isAddDailyPerformances'
            :is-view-asset-info='isViewAssetInfo'
            :is-view-venture-info='isViewVentureInfo'
            :is-view-high-yield-info='isViewHighYieldInfo'
            :daily-performance='dailyPerformance' />
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan='34'>
              <input
                type='text'
                class='form-input w-full py-12 text-left'
                placeholder="<전체 탭에서 처리하세요> 여기에 붙여넣기 (기준일자, 펀드코드, NAV, 비우량채권+코넥스주식, ...)"
                ref='pasteTextbox'
                @paste.prevent='onPasteDailyPerformance'>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import CopyPasteHelpers        from '@/utils/copy-paste-helpers'
import TableHeaderFilterSelect from '@/components/TableHeaderFilterSelect.vue'
import DailyPerformanceRow     from '@/views/DailyPerformanceRow.vue'
import dayjs                   from 'dayjs'
import cloneDeep               from 'lodash/cloneDeep'
import { DatabaseIcon, PlusCircleIcon, SaveIcon, XIcon } from '@vue-hero-icons/outline'

export default {
  name: 'DailyPerformances',
  components: {
    TableHeaderFilterSelect,
    DailyPerformanceRow,
    DatabaseIcon,
    PlusCircleIcon,
    SaveIcon,
    XIcon,
  },
  data () {
    return {
      selectedDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
      sortDir: '',
      sortKey: '',
      filterTexts: {
        fund_code: [],
        fund_name: [],
        fund_short_name: [],
        fund_target_asset: [],
      },
      filterKeys: [
        'fund_code',
        'fund_name',
        'fund_short_name',
        'fund_target_asset',
      ],
      newDailyPerformances: [],
      isAddDailyPerformances: false,
      accountType: '전체',
      isAssetData: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'accountTypes',
    ]),
    ...mapState('dailyPerformances', [
      'dailyPerformances',
    ]),
    sortedData () {
      if (this.sortDir === '') {
        return this.dailyPerformances
      } else {
        this.sortData ( this.dailyPerformances, {key: this.sortKey, direction: this.sortDir} )
        return this.dailyPerformances
      }
    },
    filteredData () {
      if (this.accountType === '전체') {
        return this.sortedData.filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
      } else {
        return this.sortedData.filter(data => data.fund_target_asset === this.accountType).filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
      }
    },
    hasDailyPerformances () {
      return this.dailyPerformances.length > 0
    },
    isViewAssetInfo () {
      return ['전체', '집합', '고유'].includes(this.accountType)
    },
    isViewVentureInfo () {
      return ['전체', '벤처'].includes(this.accountType)
    },
    isViewHighYieldInfo () {
      return ['전체', '하이일드'].includes(this.accountType)
    },
    isViewSubTotal () {
      return this.accountType === '벤처'
    },
    totalVentureNewSharesCloseValue () {
      return this.filteredData.reduce((sum, item) => sum + item.venture_new_shares_close_value, 0)
    },
    totalVentureExcludeNewSharesCloseValue () {
      return this.filteredData.reduce((sum, item) => sum + item.venture_exclude_new_shares_close_value, 0)
    },
    totalVentureTotalAssetsAmount () {
      return this.filteredData.reduce((sum, item) => sum + item.venture_total_assets_amount, 0)
    },
    totalVentureNavAmount () {
      return this.filteredData.reduce((sum, item) => sum + item.venture_nav_amount, 0)
    },
  },
  methods: {
    ...mapActions('dailyPerformances', [
      'getDailyPerformances',
      'updateDailyPerformances',
      'importMiraeDailyPerformances',
    ]),
    ...mapMutations('dailyPerformances', [
      'setDailyPerformances',
    ]),
    receiveData () {
      this.importMiraeDailyPerformances(dayjs(this.selectedDate).format('YYYY-MM-DD'))
    },
    refreshData () {
      this.isAddDailyPerformances = false
      this.getDailyPerformances(dayjs(this.selectedDate).format('YYYY-MM-DD'))
    },
    sortData (dailyPerformances, sortInfo) {
      let sortDailyPerformances = cloneDeep(dailyPerformances)
      sortDailyPerformances.sort((aSort, bSort) => {
        let aValue, bValue
        if (typeof aSort === 'string') {
          aValue = aSort[sortInfo.key].toUpperCase()
          bValue = bSort[sortInfo.key].toUpperCase()
        } else {
          aValue = aSort[sortInfo.key]
          bValue = bSort[sortInfo.key]
        }

        if (sortInfo.direction === 'asc') {
          if (aValue < bValue) {
            return -1
          } else if (aValue > bValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (aValue < bValue) {
            return 1
          } else if (aValue > bValue) {
            return -1
          } else {
            return 0
          }
        }
      })
      this.setDailyPerformances(sortDailyPerformances)
    },
    addDailyPerformances () {
      this.isAddDailyPerformances = true
      this.newDailyPerformances = []
      this.setDailyPerformances([])
    },
    onPasteDailyPerformance (data) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      pastedArray.forEach((row, index) => {
        let newRow = {
          id: index,
          created_on: dayjs(row[0]).format('YYYY-MM-DD'),
          fund_code: row[1],
          hy_nav_amount: row[2] ? parseFloat(row[2].replace(/,|\s/g, '')) : 0,
          hy_junk_bond_n_konex_close_value: row[3] ? parseFloat(row[3].replace(/,|\s/g, '')) : 0,
          hy_junk_bond_n_konex_ratio: row[4] ? parseFloat(row[4].replace(/,|\s/g, '')) : 0,
          hy_konex_close_value: row[5] ? parseFloat(row[5].replace(/,|\s/g, '')) : 0,
          hy_konex_ratio: row[6] ? parseFloat(row[6].replace(/,|\s/g, '')) : 0,
          hy_except_junk_bond_close_value: row[7] ? parseFloat(row[8].replace(/,|\s/g, '')) : 0,
          hy_except_junk_bond_ratio: row[8] ? parseFloat(row[9].replace(/,|\s/g, '')) : 0,
          hy_bond_close_value: row[9] ? parseFloat(row[9].replace(/,|\s/g, '')) : 0,
          hy_bond_ratio: row[10] ? parseFloat(row[10].replace(/,|\s/g, '')) : 0,
          hy_bond_n_konex_close_value: row[11] ? parseFloat(row[11].replace(/,|\s/g, '')) : 0,
          hy_bond_n_konex_ratio: row[12] ? parseFloat(row[12].replace(/,|\s/g, '')) : 0,
        }
        this.newDailyPerformances.push(newRow)
      })
      this.setDailyPerformances(this.newDailyPerformances)
      this.isAddDailyPerformances = true
    },
    patchDailyPerformances () {
      if (this.newDailyPerformances.length > 0) {
        this.updateDailyPerformances(this.newDailyPerformances).then(() => {
          this.refreshData()
        })
      }
    },
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  },
  mounted () {
    this.refreshData()
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-3 pl-2 pr-6 border-b uppercase tracking-wide sticky top-0 whitespace-no-wrap;
  }

</style>
