<template>
  <div class='pb-8 px-16'>
    <h1 class='text-3xl font-semibold tracking-wide mr-2'>간접투자원장관리</h1>
    <entities class='mt-4' :useComponent='true'/>

    <fund-listings class=' pt-4 mt-5'/>

    <div class='overflow-x-scroll investments-container mt-5'>
      <div class='flex flex-row my-6'>
        <h3 class='p-2 text-xl'>투자정보</h3>
        <div class='relative'>
          <input class='form-input' type='text' placeholder='투자명' v-model='searchText' @keyup.enter='getInvestmentByText' />
        </div>
        <button class='px-4 font-bold border rounded-md border-transparent hover:border-gray-500' @click='getInvestmentByRefresh'>Refresh</button>
      </div>
      <table class='text-sm'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b border-t'>
            <th class='px-2 py-2 text-center border-l border-r border-t'></th>
            <th class='px-2 py-2 text-center border-r' colspan='13'>투자</th>
            <th class='px-2 py-2 text-center border-r' colspan='3'>딜소싱</th>
            <th class='px-2 py-2 text-center border-r' colspan='2'>회수</th>
            <th class='px-2 py-2 text-center border-r'></th>
          </tr>
        </thead>
        <tbody>
          <tr class='text-left uppercase tracking-wide border-b'>
            <th class='px-2 py-2 text-left border-r border-l border-t'></th>
            <th class='px-2 py-2 text-left border-r'>투자번호</th>
            <th class='px-2 py-2 text-left border-r'>투자자산번호</th>
            <th class='px-2 py-2 text-left border-r'>투자명</th>
            <th class='px-2 py-2 text-left border-r'>종목구분</th>
            <th class='px-2 py-2 text-left border-r'>구주신주</th>
            <th class='px-2 py-2 text-left border-r'>투자일</th>
            <th class='px-2 py-2 text-left border-r'>투자단가</th>
            <th class='px-2 py-2 text-left border-r'>투자단가(조정)</th>
            <th class='px-2 py-2 text-left border-r'>보호예수종료일</th>
            <th class='px-2 py-2 text-left border-r'>보관</th>
            <th class='px-2 py-2 text-left border-r'>담당자(지원)</th>
            <th class='px-2 py-2 text-left border-r'>공동투자자</th>
            <th class='px-2 py-2 text-left border-r'>회사명</th>
            <th class='px-2 py-2 text-left border-r'>담당자</th>
            <th class='px-2 py-2 text-left border-r'>당사담당자</th>
            <th class='px-2 py-2 text-left border-r'>유동가능일</th>
            <th class='px-2 py-2 text-left border-r'>회수가능일</th>
            <th class='px-2 py-2 text-left border-r'>회수가능일(Mkt)</th>
            <th class='px-2 py-2 text-center border-r'></th>
          </tr>
          <tr v-for='investment in investments'
            :key='`investment-${investment.id}-${investment.investment_detail_id}`'
            :class='highlightRow(investment.id)'>
            <td class='px-2 py-2 text-left border-r border-l'>
              <input type='checkbox' disabled class='form-checkbox' v-model='investment.check'>
            </td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-right border-r'>{{ investment.id }}</td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-right border-r'>{{ investment.entity_investment_seq }}</td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-left border-r'>{{ investment.investment_name }}</td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-left border-r'>{{ investment.asset_class }}</td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-left border-r'>{{ investment.is_new_shares }}</td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-left border-r'>{{ investment.investment_date }}</td>
            <td @click='selectInvestment(investment)' class='px-2 py-2 text-right border-r'>{{ investment.investment_price }}</td>
            <td class='px-2 py-2 text-right border-r'>
              <input class='form-input text-right w-32' type='number' v-model='investment.changed_price' @keyup.enter='submitDetail(investment)' />
            </td>
            <td>
              <el-date-picker
                v-model='investment.lockup_expiration_date'
                value-format='yyyy-MM-dd'
                type='date'
                placeholder='보호예수종료일'>
              </el-date-picker>
            </td>
            <td class='text-left border-r'>
              <select v-model='investment.asset_storages' class='form-select w-24'>
                <option value=등록>등록</option>
                <option value=실무>실무</option>
                <option value=보호예수>보호예수</option>
              </select>
            </td>
            <td class='px-2 py-2 text-left border-r'>{{ investment.support_staff_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ investment.other_investor }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ investment.entity_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ investment.opposite_staff_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ investment.staff_name }}</td>
            <td>
              <el-date-picker
                v-model='investment.possible_sell_date'
                value-format='yyyy-MM-dd'
                type='date'
                placeholder='유동가능일'>
              </el-date-picker>
            </td>
            <td>
              <el-date-picker
                v-model='investment.collection_date'
                value-format='yyyy-MM-dd'
                type='date'
                placeholder='회수가능일'>
              </el-date-picker>
            </td>
            <td>
              <el-date-picker
                v-model='investment.mkt_collection_date'
                value-format='yyyy-MM-dd'
                type='date'
                placeholder='회수가능일(Mkt)'>
              </el-date-picker>
            <td>
              <button class='text-center w-16' @click='submitDetail(investment)'>저장</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <orders class='orders-container pt-4 mt-5'
      :orders='orders'
      :investmentId='selectedInvestment.id'
      :investmentDetailId='selectedInvestment.investment_detail_id'
      :investmentName='selectedInvestment.investment_name'
      :entityInvestmentSeq='selectedInvestment.entity_investment_seq'
      @done-editing='doneEditOrderPosition'/>

    <div class='flex flex-row pt-4 mt-5'>
      <h3 class='p-2 text-xl'>자산</h3>
      <el-date-picker
        v-model='createdOn'
        value-format='yyyy-MM-dd'
        type='date'
        @change='getPositions'
        placeholder='잔고기준일'>
      </el-date-picker>
    </div>

    <positions class='positions-container mt-4 mt-5'
      :positions='positions'
      :is_direct='isDirect'
      @done-editing='doneEditOrderPosition'/>

  </div>
</template>

<script>
import { mapActions, mapGetters }            from 'vuex'
import Entities                              from '@/views/Entities.vue'
import FundListings                          from '@/views/investment_ledger/FundListings.vue'
import Orders                                from '@/views/investment_ledger/Orders.vue'
import Positions                             from '@/views/investment_ledger/Positions.vue'
import numbro                                from 'numbro'
import dayjs                                 from 'dayjs'
import PositionsApi                          from '@/api/v1/positions'
import OrdersApi                             from '@/api/v1/orders'
import InvestmentsApi                        from '@/api/v1/investments'

export default {
  name: 'IndirectLedger',
  components: {
    Entities,
    FundListings,
    Orders,
    Positions,
  },
  data () {
    return {
      orders: '',
      positions: '',
      investments: '',
      searchText: '',
      createdOn: dayjs(new Date()).format('YYYY-MM-DD'),
      selectedInvestment: '',
      isDirect: false,
    }
  },
  watch: {
    'drilldownEntityId': {
      handler: function () {
        if (this.drilldownEntityId > 0) {
          this.getInvestmentEntity ()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('entities', [
      'drilldownEntityName',
      'drilldownEntityId',
    ]),
  },
  methods: {
    ...mapActions('entities', [
      'resetDrilldownEntity'
    ]),
    highlightRow (id) {
      return id === this.selectedInvestment.id ? 'bg-gray-200 text-gray-900 font-semibold' : ''
    },
    getOrders () {
      OrdersApi.getOrders({ investment_id: this.selectedInvestment.id, is_direct: this.isDirect }).then(resp => {
        this.orders = resp
      })
    },
    getPositions () {
      if (this.selectedInvestment.id > 0) {
        PositionsApi.getPositionsByDateInvestment({ created_on: this.createdOn, investment_id: this.selectedInvestment.id, is_direct: this.isDirect }).then(resp => {
          this.positions = resp
        })
      }
    },
    getInvestmentEntity () {
      InvestmentsApi.getInvestmentForLedger({ entity_id: this.drilldownEntityId, is_direct: this.isDirect, search_text: '' }).then(resp => {
        this.investments = resp
      })
    },
    getInvestmentByText () {
      if (this.searchText.length > 0) {
        InvestmentsApi.getInvestmentForLedger({ entity_id: 0, is_direct: this.isDirect, search_text: this.searchText }).then(resp => {
          this.investments = resp
        })
      }
    },
    getInvestmentByRefresh () {
      if (this.searchText.length > 0) {
        this.getInvestmentByText () 
      } else {
        this.getInvestmentEntity () 
      }
    },
    selectInvestment (investment) {
      this.selectedInvestment = investment
      this.getOrders()
      this.getPositions()
      this.investments.forEach((investment) => investment.check = false)
      investment.check = true
    },
    submitDetail (investment) {
      InvestmentsApi.updateInvestmentDetail(investment).then(resp => {
        alert(resp)
      })
    },
    doneEditOrderPosition () {
      this.getOrders()
      this.getPositions()
    },
    toNumber (number) {
      return numbro(number).format({ thousandSeparated: true })
    },
  },
  mounted () {
    this.resetDrilldownEntity ()
  }
}
</script>
