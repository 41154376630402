<template>
  <div>
    <div class='overflow-x-scroll  mt-5'>
      <div class='flex flex-row my-6'>
        <h3 class='p-2 text-xl'>종목정보</h3>
        <button
          class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          name='update-listing-form-btn'
          @click='updateListing("insert")'>
          <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
        </button>
        <div class='relative'>
          <input class='form-input' type='text' placeholder='종목코드/종목명' v-model='searchText' @keyup.enter='refreshSearch' />
        </div>
      </div>
      <table class='text-sm'>
        <thead>
          <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
            <th class='px-2 py-2 text-left border-r border-l border-t'>선택</th>
            <th class='px-2 py-2 text-left border-r border-t'>종목코드</th>
            <th class='px-2 py-2 text-left border-r border-t'>표준코드</th>
            <th class='px-2 py-2 text-left border-r border-t'>기업코드</th>
            <th class='px-2 py-2 text-left border-r border-t'>종목명</th>
            <th class='px-2 py-2 text-left border-r border-t'>발행일</th>
            <th class='px-2 py-2 text-left border-r border-t'>전환가능일</th>
            <th class='px-2 py-2 text-left border-r border-t'>만기일</th>
            <th class='px-2 py-2 text-left border-r border-t'>회차</th>
            <th class='px-2 py-2 text-left border-r border-t'>표면이자율</th>
            <th class='px-2 py-2 text-left border-r border-t'>ytm</th>
            <th class='px-2 py-2 text-left border-r border-t'>ytp</th>
            <th class='px-2 py-2 text-left border-r border-t'>ytc</th>
            <th class='px-2 py-2 text-right border-r border-t'>리픽싱(%)</th>
            <th class='px-2 py-2 text-left border-r border-t'>리픽싱기준</th>
            <th class='px-2 py-2 text-left border-r border-t'>리픽싱단위</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='listing in listings'
            :key='`listing-${listing.id}`'
            @click='selectListing(listing)'
            @dblclick='updateListing("update")'
            :class='highlightRow(listing.id)'>
            <td class='px-2 py-2 text-left border-r border-l'>
              <input type='checkbox' disabled class='form-checkbox' v-model='listing.check'>
            </td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.isin_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.company_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.start_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.conversion_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.end_date }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.issued_seq }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.coupon_rate }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.ytm }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.ytp }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.ytc }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.refixing_rate }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.refixing_base }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.refixing_unit }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class='flex mt-4'>
      <refixings class='overflow-x-scroll refixing-container mt-5' />
      <options class='overflow-x-scroll options-container mt-5' />

      <div class='overflow-x-scroll put-container mt-5 px-16'>
        <div class='flex flex-row my-1'>
          <h4>표면이자</h4>
          <button
            class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
            name='update-iDate-form-btn'
            @click='addNewDate'>
            <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
          </button>
        </div>

        <table class='mt-4 text-sm'>
          <thead>
            <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
              <th class='px-2 py-2 text-left border-r'>날짜</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='iDateRec in iDateRecs'
              :key='`iDate-${iDateRec.index}`'>
              <td class='px-2 text-left border-r border-l'>
                <input type='text' v-model='iDateRec.iDate' class='text-base px-4 w-32' placeholder='0000-00-00' pattern='\d{4}-\d{2}-\d{2}'
                  @paste.prevent='onPasteiDate($event, iDateRec.index)'
                />
              </td>
            </tr>
          </tbody>
          <tfoot><tr><td colspan='100' class='text-right py-4'>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='updateInterestDate'>Save Changes</button></td></tr>
          </tfoot>
        </table>
      </div>
    </div>

    <ledger-modal v-if='editListing' @done-editing='doneEditListing' :modalName='"listing"' :modalStyle='"height: 800px; width: 1000px;"' />

  </div>
</template>


<script>
import { mapActions, mapState, mapGetters }  from 'vuex'
import Refixings                             from '@/views/investment_ledger/Refixings.vue'
import Options                               from '@/views/investment_ledger/Options.vue'
import LedgerModal                           from '@/views/investment_ledger/LedgerModal.vue'
import { PlusCircleIcon }                    from '@vue-hero-icons/outline'
import numbro                                from 'numbro'
import ListingsApi                           from '@/api/v1/listings'
import CopyPasteHelpers                      from '@/utils/copy-paste-helpers'
import cloneDeep                             from 'lodash/cloneDeep'


export default {
  name: 'Listings',
  components: {
    PlusCircleIcon,
    Refixings,
    Options,
    LedgerModal,
  },
  data () {
    return {
      searchText: '',
      editListing: false,
      iDateRecs: [{}],
      listing: {},
      isDirect: true,
    }
  },
  watch: {
    'drilldownEntityId': {
      handler: function () {
        this.getEntityListings ( { entity_id: this.drilldownEntityId, is_direct: this.isDirect })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('listings', [
      'listings',
      'refixingSchedules',
      'drilldownListing',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityName',
      'drilldownEntityId',
    ]),
  },
  methods: {
    ...mapActions('listings', [
      'getListings',
      'getEntityListings',
      'setSelectedListing',
      'resetDrilldownListing',
    ]),
    highlightRow (id) {
      return id === this.drilldownListing.id ? 'bg-blue-100 text-gray-900 font-semibold' : ''
    },
    addNewDate () {
      this.iDateRecs.push({index: this.iDateRecs.length, iDate: ''})
    },
    selectListing (listing) {
      this.setSelectedListing(listing)
      this.iDateRecs = []
      this.drilldownListing.interest_dates.forEach((iDate,index) => {
        this.iDateRecs.push({index: index, iDate: iDate})
      })
      this.listings.forEach((listing) => listing.check = false)
      listing.check = true
    },
    refreshSearch () {
      this.getListings({ search_text: this.searchText, is_direct: this.isDirect })
    },
    updateListing (type) {
      if (type === 'insert') {
        this.resetDrilldownListing().then ( () => {
          this.editListing = true
        })
      } else {
        this.editListing = true
      }
    },
    doneEditListing () {
      this.editListing = false
      if (this.searchText.length > 0) {
        this.refreshSearch ()
      } else {
        this.getEntityListings ({ entity_id: this.drilldownEntityId, is_direct: this.isDirect })
      }
    },
    toNumber (number) {
      return numbro(number).format({ thousandSeparated: true })
    },
    onPasteiDate (data, iDateIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)
      let iDateLength = this.iDateRecs.length

      pastedArray.forEach((row, index) => {
        if (index + iDateIndex >= iDateLength) {
          this.iDateRecs.push({index: index + iDateIndex, iDate: row[0]})
        } else {
          this.iDateRecs[index + iDateIndex].iDate = row[0]
        }
      })
    },
    updateInterestDate () {
      if (this.drilldownListing.id > 0) {
        this.listing = cloneDeep(this.drilldownListing)
        this.listing.interest_dates = []
        this.iDateRecs.forEach(iDateRec => {
          if (iDateRec.iDate.length === 10) {
            this.listing.interest_dates.push(iDateRec.iDate)
          }
        })
        ListingsApi.patchListing(this.drilldownListing.id, this.listing).then(resp => {
          this.setSelectedListing(resp)
          if (this.searchText.length > 0) {
            this.refreshSearch ()
          } else {
            this.getEntityListings ({ entity_id: this.drilldownEntityId, is_direct: this.isDirect })
          }
        })
      }
    },
  },
}
</script>
