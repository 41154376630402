<template>
  <section class='overflow-x-scroll py-8'>
    <div class='flex flex-row justify-start gap-x-4 items-stretch'>
      <button class='flex flex-row justify-start gap-x-1 items-center py-2' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4' />
        투자원장관리
      </button>
      <button
        @click='copyToClipboard'
        class='pl-3 pr-4 flex flex-row justify-start gap-x-0.5 items-center py-2 rounded-md text-sm text-gray-700 border border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'>
        <clipboard-copy-icon class='h-4'/>
        복사
      </button>
      <a class='pl-3 pr-4 flex flex-row justify-start gap-x-0.5 items-center py-2 rounded-md text-sm text-gray-700 border border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md' href='https://www.cretop.com/?signal=1637568559751' target='_blank'>
        <external-link-icon class='h-4'/>
        크레탑
      </a>
    </div>
    <table v-if='showDetails'
      class='ipo-table'>
      <thead>
        <tr>
          <th>기업코드</th>
          <th>기업명</th>
          <th>법인구분</th>
          <th>법인번호</th>
          <th>사업자등록번호</th>
          <th>대표이사</th>
          <th>상장주간사</th>
          <th>상장진행현황</th>
          <th>주요사업</th>
          <th>대분류</th>
          <th>중분류</th>
          <th>소분류</th>
        </tr>
      </thead>
      <tbody>
        <tr class='border-t'>
          <td>A{{selectedIpo.share_code}}</td>
          <td>{{selectedIpo.name}}</td>
          <td>국내설립법인</td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 10rem'
              @change='updateUserDetail("법인번호")'
              v-model='corporateNumber'>
          </td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 10rem'
              @change='updateUserDetail("사업자등록번호")'
              v-model='corporateRegistrationNumber'>
          </td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 10rem'
              @change='updateUserDetail("대표이사")'
              v-model='ceoName'>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <select v-model='largeCategory'
              class='form-select'
              @change='updateUserDetail("대분류")'>
              <option v-for='classificationType in classificationTypes'
                      :key='classificationType'>
                {{classificationType}}
              </option>
            </select>
          </td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 8rem'
              @change='updateUserDetail("중분류")'
              v-model='middleCategory'>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon, ClipboardCopyIcon, ExternalLinkIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoInvestmentManagement',
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    ClipboardCopyIcon,
    ExternalLinkIcon,
  },
  data () {
    return {
      showDetails: false,
      corporateNumber: '',
      corporateRegistrationNumber: '',
      ceoName: '',
      largeCategory: '',
      middleCategory: '',
      classificationTypes: [
        '',
        'IT',
        '금융',
        '기타',
        '바이오',
        '산업재',
        '소비재',
        '소재/에너지',
        '엔터',
        '자동차',
      ],
    }
  },
  watch: {
    'selectedIpo.details': {
      handler: function (newVal) {
        if (newVal) {
          this.corporateNumber = this.selectedIpo.details &&
                                 this.selectedIpo.details[this.currentIpoDetailsName] &&
                                 this.selectedIpo.details[this.currentIpoDetailsName]['법인번호'] ? this.selectedIpo.details[this.currentIpoDetailsName]['법인번호'] : ''
          this.corporateRegistrationNumber = this.selectedIpo.details &&
                                 this.selectedIpo.details[this.currentIpoDetailsName] &&
                                 this.selectedIpo.details[this.currentIpoDetailsName]['사업자등록번호'] ? this.selectedIpo.details[this.currentIpoDetailsName]['사업자등록번호'] : ''
          this.ceoName = this.selectedIpo.details &&
                                 this.selectedIpo.details[this.currentIpoDetailsName] &&
                                 this.selectedIpo.details[this.currentIpoDetailsName]['대표이사'] ? this.selectedIpo.details[this.currentIpoDetailsName]['대표이사'] : ''
          this.largeCategory = this.selectedIpo.details &&
                                 this.selectedIpo.details[this.currentIpoDetailsName] &&
                                 this.selectedIpo.details[this.currentIpoDetailsName]['대분류'] ? this.selectedIpo.details[this.currentIpoDetailsName]['대분류'] : ''
          this.middleCategory = this.selectedIpo.details &&
                                 this.selectedIpo.details[this.currentIpoDetailsName] &&
                                 this.selectedIpo.details[this.currentIpoDetailsName]['중분류'] ? this.selectedIpo.details[this.currentIpoDetailsName]['중분류'] : ''
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    ...mapGetters('users', [
      'currentIpoDetailsName',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    hasSelectedIpo () {
      return Object.keys(this.selectedIpo).length > 0
    },
   },
  methods: {
    ...mapActions ('ipos', [
      'updateIpoDetail',
      'patchIpoDetails',
    ]),
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    copyToClipboard () {
      let result = [
        'A'+this.selectedIpo.share_code,
        this.selectedIpo.name,
        '국내설립법인',
        this.corporateNumber,
        this.corporateRegistrationNumber,
        this.ceoName,
        '',
        '',
        '',
        this.largeCategory,
        this.middleCategory,
        '',
      ].join('\t')
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: '클립보드에 복사 됨.',
        })
      })
    },
    updateUserDetail (key) {
      if (event) {
        this.updateIpoDetail( {id: this.selectedIpo.id, currentIpoDetailsName: this.currentIpoDetailsName, key: key, type: 'text', value: event.target.value} ).then(() => {
          this.patchIpoDetails(this.selectedIpo)
        })
      }
    },
  }
}
</script>
