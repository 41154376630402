<template>
  <div class='relative'>
    <search-icon class='absolute text-gray-500' size='20' style='top: 10px; left: 12px;' />
    <input
      v-model='searchText'
      ref='searchBox'
      type='search'
      :placeholder='placeholder'
      class='border h-10 pl-10 rounded focus:border-blue-400 focus:shadow rounded' style='width:320px;'
      @focus='searchFocusOn()'
      @blur='searchFocusOff()'>
      <x-circle-icon v-if='hasSearchText' @click='clearSearchText()' class='absolute cursor-pointer' style='top: 8px; right: 8px;'/>
  </div>
</template>

<script>
import debounce                 from 'debounce'
import { SearchIcon, XCircleIcon }  from '@vue-hero-icons/outline'
import { mapActions }  from 'vuex'

export default {
  name: 'EntityFilters',
  components: {
    SearchIcon,
    XCircleIcon,
  },
  props: [ 'entityName' ],
  data () {
    return {
      searchFocus: false,
      searchText: ' ',
    }
  },
  watch: {
    'searchText': {
      handler: function (newVal, oldVal) {
        if (newVal.trim() !== '' && newVal.trim() !== oldVal.trim()) {
          this.searchAll(newVal)
        }
      },
      immediate: false
    },
  },
  computed: {
    hasSearchText () {
      return this.searchText.trim() !== ''
    },
    placeholder () {
      return 'Search Entity'
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
    ]),
    ...mapActions('entities', [
      'getEntities',
    ]),
    clearSearchText () {
      this.searchText = ' '
      this.searchAll('')
    },
    searchFocusOff () {
      this.searchText = this.searchText + ' '
      this.searchFocus = false
      if (this.searchText.trim() === '') {
        this.searchAll('')
      }
    },
    searchAll (searchString) {
      this.getEntities({ search_text: searchString })
    },
    searchFocusOn () {
      this.searchText = this.searchText.trim()
      this.searchFocus = true
      this.sidepanelClose()
    },
    initialText () {
      return this.entityName ? this.entityName : ' '
    }
  },
  created () {
    this.searchAll = debounce(this.searchAll, 500)
  },
  mounted () {
    this.searchAll(this.initialText())
  }
}
</script>
