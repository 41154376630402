<template>
  <div>
    <div class='flex flex-row items-center justify-start gap-x-8 overflow-x-auto ipo-portfolio-controls py-2 lg:py-4'>
      <el-radio-group
        v-model='isPosition'
        size='small'>
        <el-radio-button label='보유중'></el-radio-button>
        <el-radio-button label='전체'></el-radio-button>
      </el-radio-group>
      <button
        class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap py-2 pl-2 pr-2 border border-gray-200 text-sm rounded-md text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-entity-form-btn'
        @click='copyToClipboard'>
        <clipboard-copy-icon class='h-4' />
        <span class='text-xs'>Copy to Clipboard</span>
      </button>
    </div>
    <section class='lg:mt-2 ipo-portfolio-table-container'>
      <table id='ipoPortfolio'
        class='ipo-table border-l border-r'>
        <thead class='sticky top-0 bg-white'>
          <tr>
            <th
              class='border-b border-t'
              rowspan='2'>
              <div class='flex flex-row justify-between items-center'>
                <button
                  class='flex flex-row justify-between items-center p-0.5'
                  @click='toggleShowAll'>
                  <component :is='toggleIcon' class='h-4 mb-1 inline-block' />
                  <span>종목명</span>
                </button>
                <label class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap'>
                  <input type='checkbox'
                    class='form-checkbox'
                    name='exceptSpac'
                    v-model='exceptSpac'>
                  <span class='text-xs'>스팩 제외</span>
                </label>
              </div>
            </th>
            <th class='border-b border-l border-t' rowspan='2'>기관경쟁률</th>
            <th class='border-b border-l border-t text-right' rowspan='2'>의무보유확약</th>
            <th class='border-b border-l border-t text-right' rowspan='2'>청약경쟁률</th>
            <th class='border-b border-l border-t text-right' rowspan='2'>상장일</th>
            <th class='border-b border-l border-t text-right' rowspan='2'>확정공모가</th>
            <th class='border-b border-l border-t text-right' rowspan='2'>전일주가</th>
            <th class='border-b border-l border-t text-right' rowspan='2'>괴리율</th>
            <th class='border-b border-l border-t text-center' colspan='3'>배정내역</th>
            <th class='border-b border-l border-t text-center' colspan='6'>보유/회수내역</th>
            <th class='border-b border-l border-t' rowspan='2'>락업해제일</th>
            <th class='border-b border-l border-t' rowspan='2'>비고</th>
            <th class='border-b border-l border-t' rowspan='2'>증권신고서</th>
          </tr>
          <tr>
            <th class='border-b border-l text-right'>배정수량</th>
            <th class='border-b border-l text-right'>배정금액</th>
            <th class='border-b border-l text-right'>배정비중</th>
            <th class='border-b border-l text-right'>현재보유수량</th>
            <th class='border-b border-l text-right'>보유평가액</th>
            <th class='border-b border-l text-right'>매도평단</th>
            <th class='border-b border-l text-right'>회수손익</th>
            <th class='border-b border-l text-right'>회수율</th>
            <th class='border-b border-l text-right'>수익률</th>
          </tr>
        </thead>
        <ipo-portfolio-group
          v-for='ipo in filteredData'
          :key='ipo.name'
          :style='selectedRow(ipo.id)'
          @select-row='selectRow'
          :show-all='showAll'
          :ipo='ipo' />
      </table>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { ClipboardCopyIcon, ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'
import IpoPortfolioGroup from '@/views/ipo/IpoPortfolioGroup.vue'

export default {
  name: 'IpoPortfolio',
  components: {
    IpoPortfolioGroup,
    ClipboardCopyIcon,
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      selectedIpoId: 0,
      showAll: false,
      isPosition: '보유중',
      exceptSpac: true,
    }
  },
  computed: {
    ...mapFields('ipos', [
      'ipoPortfolio',
    ]),
    positionData () {
      return this.isPosition === '보유중' ? this.ipoPortfolio.filter(port => port.ipo_position_id > 0) : this.ipoPortfolio
    },
    filteredData () {
      return this.exceptSpac ? this.positionData.filter(port => !(port.name.includes('스팩') || port.name.includes('기업인수'))) : this.positionData
    },
    toggleIcon () {
      return this.showAll ? 'chevron-down-icon' : 'chevron-right-icon'
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpoPortfolio',
    ]),
    refreshData () {
      this.getIpoPortfolio()
    },
    selectedRow (ipoId) {
      return (this.selectedIpoId == ipoId) ? 'background-color: lightyellow' : ''
    },
    selectRow (ipoId) {
      this.selectedIpoId = ipoId
    },
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    toggleShowAll () {
      this.showAll = !this.showAll
    },
    copyToClipboard () {
      let headers = ['종목명', '기관경쟁률', '의무보유확약', '청약경쟁률', '상장일', '확정공모가', '전일주가', '괴리율', '배정수량', '배정금액', '배정비중', '현재보유수량', '보유평가액', '매도평단', '회수손익', '회수율', '수익률', '락업해제일', '비고']
      let result = `${headers.join('\t')}\n`
      this.filteredData.forEach(ipo => {
        var allocatedPrice = ipo.details['확정공모가'] ? ipo.details['확정공모가'] : 0
        var baseAllocatedQuantity = ipo.details['기관배정주식수'] ? parseFloat(ipo.details['기관배정주식수']) : 0
        var positionQuantity = ipo.portfolios.reduce((sum, port) => sum + port.position_quantity, 0)
        var positionCloseValue = ipo.portfolios.reduce((sum, port) => sum + port.position_close_value, 0)
        var recovery = ipo.allocated_quantity === 0 ? '' : `${((ipo.allocated_quantity - positionQuantity) / ipo.allocated_quantity * 100).toFixed(1)} %`
        var disparateRatio = allocatedPrice === 0 ? '' : `${((ipo.close_price / allocatedPrice - 1) * 100).toFixed(1)} %`
        var allocatedRate = baseAllocatedQuantity === 0 ? '' : `${(ipo.allocated_quantity / baseAllocatedQuantity * 100).toFixed(3)} %`

        let sum = 0
        let count = 0
        ipo.portfolios.forEach(row => {
          if (row.average_selling_price > 0) {
            sum += row.average_selling_price
            count += 1
          }
        })

        var averageSellingPrice = count === 0 ? '' : parseFloat(sum / count).toFixed(0)
        var recoveryGainLoss = ipo.allocated_quantity === 0 ? '' :
                                Number(averageSellingPrice) === 0 ? '' : (averageSellingPrice - allocatedPrice) * (ipo.allocated_quantity - positionQuantity)
        var earningsRatio = (Number(recoveryGainLoss) === 0 || ipo.allocated_quantity === 0) ? '' : `${(recoveryGainLoss / ipo.allocated_amount * 100).toFixed(1)} %`

        let rawData = [
          ipo.name,
          ipo.details['기관경쟁률'],
          ipo.details['의무보유확약'],
          this.competitionRate(ipo.details['청약경쟁률']),
          ipo.listing_date,
          this.numberStyle(ipo.details['확정공모가']),
          this.numberStyle(ipo.close_price),
          disparateRatio,
          this.numberStyle(ipo.allocated_quantity),
          this.numberStyle(ipo.allocated_amount),
          allocatedRate,
          this.numberStyle(positionQuantity),
          this.numberStyle(positionCloseValue),
          this.numberStyle(averageSellingPrice),
          this.numberStyle(recoveryGainLoss),
          recovery,
          earningsRatio,
          ipo.lockup_expiration_date,
          '',
        ]
        result += `${rawData.join('\t')}\n`

        ipo.portfolios.forEach(portfolio => {
          var publicOfferingShares

          if (portfolio.account_type === '집합') {
            publicOfferingShares = ipo.details['기관배정주식수'] ? parseFloat(ipo.details['기관배정주식수']) : 0
          } else if (portfolio.account_type === '벤처') {
            publicOfferingShares = ipo.details['총공모주식수'] ? parseFloat(ipo.details['총공모주식수']) * 0.3 : 0
          } else if (portfolio.account_type === '하이일드') {
            publicOfferingShares = ipo.details['총공모주식수'] ? parseFloat(ipo.details['총공모주식수']) * 0.05 : 0
          } else if (portfolio.account_type === '고유') {
            publicOfferingShares = ipo.details['기관배정주식수'] ? ipo.details['기관배정주식수'] : 0
          } else {
            publicOfferingShares = 0
          }

          var portfolioAverageSellingPrice = portfolio.average_selling_price ? portfolio.average_selling_price.toFixed(0) : ''
          var portfolioAllocatedRate = (portfolio.allocated_quantity === 0 || publicOfferingShares === 0) ? '' : `${(portfolio.allocated_quantity / publicOfferingShares * 100).toFixed(3)} %`
          var portfolioRecoveryGainLoss = portfolio.allocated_quantity === 0 ? '' :
                                            Number(portfolioAverageSellingPrice) === 0 ? '' : ((portfolioAverageSellingPrice - allocatedPrice) * (portfolio.allocated_quantity - portfolio.position_quantity)).toFixed(0)
          var portfolioRecovery = (portfolio.position_quantity === 0 || portfolio.allocated_quantity === 0) ? '' : `${((portfolio.allocated_quantity - portfolio.position_quantity) / portfolio.allocated_quantity * 100).toFixed(1)} %`
          var portfolioEarningsRatio = (Number(portfolioRecoveryGainLoss) === 0 || portfolio.allocated_quantity === 0) ? '' : `${(portfolioRecoveryGainLoss / portfolio.allocated_amount * 100).toFixed(1)} %`

          let rawData = [
            portfolio.account_type,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            this.numberStyle(portfolio.allocated_quantity),
            this.numberStyle(portfolio.allocated_amount),
            portfolioAllocatedRate,
            this.numberStyle(portfolio.position_quantity),
            this.numberStyle(portfolio.position_close_value),
            this.numberStyle(portfolioAverageSellingPrice),
            this.numberStyle(portfolioRecoveryGainLoss),
            portfolioRecovery,
            portfolioEarningsRatio,
            portfolio.lockup_expiration_date,
            portfolio.note,
          ]
          result += `${rawData.join('\t')}\n`
        })
      })
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: '클립보드에 복사 됨.',
        })
      })
    },
    competitionRate (ipo) {
      if (ipo) {
        return (ipo).includes('비례') ? ipo.split('(비례')[0] : ipo
      } else {
        return ''
      }
    },
  },
  mounted () {
    this.refreshData()
  },
}
</script>

<style lang='scss'>
  .ipo-portfolio-controls .el-radio-button__inner,
  .ipo-portfolio-controls .el-radio-group {
    @apply flex flex-row;
  }

</style>

<style lang='scss' scoped>

  table {
    border-collapse: separate;
  }
  .ipo-portfolio-table-container {
    @apply overflow-auto;
    width: 100%;
    height: calc(100vh - 10rem);
  }

  @media (min-width: 1024px) {
    .ipo-portfolio-table-container {
      height: calc(100vh - 17.5rem);
    }
  }

</style>
