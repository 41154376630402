<template>
  <div class='pb-8 px-16'>
    <div class='flex flex-row my-6'>
      <h1 class='mr-2 text-3xl font-semibold tracking-wide'>LPs</h1>
      <button
        class='border p-3 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='limitedPartnerDetailsOpen'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;'></plus-circle-icon>
      </button>
    </div>
    <table class='text-sm'>
      <thead>
        <tr>
          <th>LP -> Fund</th>
          <th>Commitments (억)</th>
          <th>Callable Capital (억)</th>
          <th>Contacts</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='limitedPartner in limitedPartners' :key='`limited-partner-${limitedPartner.id}`'
          @click='limitedPartnerDetailsOpen(limitedPartner)'>
          <td class='py-4'>{{limitedPartner.limited_partner_name}}</td>
          <td class='py-4'>{{limitedPartner.commitment}}</td>
          <td class='py-4'>{{limitedPartner.callable_capital}}</td>
          <td class='py-4'>{{limitedPartner.contacts}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { PlusCircleIcon }       from '@vue-hero-icons/outline'

export default {
  name: 'LimitedPartners',
  components: {
    PlusCircleIcon,
  },
  computed: {
    ...mapState('limitedPartners', [
      'limitedPartners',
    ]),
  },
  methods: {
    ...mapActions('limitedPartners', [
      'limitedPartnerDetailsOpen',
      'getLimitedPartners',
    ]),
  },
  mounted () {
    this.getLimitedPartners()
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-left uppercase tracking-wide;
  }
  th, td {
    @apply py-3 pl-2 pr-6 border-b;
  }

  td {
    @apply cursor-pointer text-gray-700;
  }
  td:hover {
    @apply bg-gray-100 text-gray-900;
  }

</style>
