<template>
  <div>
    <div class='tabs-container px-2 lg:px-8 pt-4 pb-2 border-b border-gray-100 text-gray-600'>
      <label
       :class='selectedStyle("ledger-pi-code-edit")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer hover:bg-gray-100'>
        <input value='ledger-pi-code-edit' v-model='view' type='radio' name='view' class='hidden'/>
        회계상구분 관리
      </label>
      <label
       :class='selectedStyle("proper-settings")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer hover:bg-gray-100'>
        <input value='proper-settings' v-model='view' type='radio' name='view' class='hidden'/>
        종목별 회계상구분
      </label>
    </div>
    <component :is='view' />
  </div>
</template>

<script>
import { RefreshIcon }       from '@vue-hero-icons/outline'
import LedgerPiCodeEdit      from '@/views/portfolio/LedgerPiCodeEdit.vue'
import ProperSettings        from '@/views/portfolio/ProperSettings.vue'

export default {
  name: 'LedgerPiCodes',
  components: {
    RefreshIcon,
    LedgerPiCodeEdit,
    ProperSettings,
  },
  data () {
    return {
      view: 'ledger-pi-code-edit',
    }
  },
  methods: {
    selectedStyle (tab) {
      return (tab === this.view) ? 'text-gray-800 font-semibold border-b-2 border-gray-800' : 'border-b-2 border-transparent'
    }
  },
}
</script>
