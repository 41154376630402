<template>
  <div class='pb-8 px-16 mt-4'>
    <div class='flex flex-row my-1 py-2'>
      <input class='form-input' type='text' placeholder='자산명' v-model='filterText'/>
      <span class='text-base mt-2 ml-4 w-full' @click='result=""'>{{ result }}</span>
    </div>
    <table class='mt-4 text-sm'>
      <thead>
        <tr class='text-left uppercase tracking-wide border-b border-t'>
          <th class='px-2 py-2 bg-gray-100 text-left border-r border-l'>자산코드</th>
          <th class='px-2 py-2 bg-gray-100 text-left border-r'>자산명</th>
          <th class='px-2 py-2 bg-gray-100 text-left border-r'>회계상구분</th>
          <th class='px-2 py-2 bg-gray-100 text-left border-r'>세부종목명</th>
          <th class='px-2 py-2 bg-gray-100 text-left border-r'>상장주식수</th>
          <td class='px-2 py-2 bg-gray-100 text-left border-r'>
            <button class='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
              @click='uploadAllProperSetting()'>
              저장
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for='properSetting in filteredProperSettings'
          :key='`properSetting-${properSetting.id}`'>
          <td class='px-2 border-r border-l border-b text-right'>
            {{ properSetting.shareCode }}
          </td>
          <td class='px-2 border-r border-l border-b text-left'>
            {{ properSetting.name }}
          <td class='px-2 border-r border-l'>
            <v-select v-model='properSetting.accountingType'
              :options='accountingList'
              label='회계상구분'
              :close-on-select='true'
              @close='properSetting.update="Y"'
              class='w-full'>
            </v-select>
          </td>
          <td class='px-2 border-r border-l'>
            <input class='text-left border py-2 px-2 rounded'
              type='text'
              v-model='properSetting.name2'>
          </td>
          <td class='px-2 border-r border-l'>
            <input class='text-right border py-2 px-2 rounded'
              type='numeric'
              v-model='properSetting.totalIssueQuantity'>
          </td>
          <td class='text-center'>
            <button class='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
              @click='uploadProperSetting(properSetting)'>
              저장
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import ProperSettingsApi     from '@/api/v1/proper_settings'
import LedgerCodeApi         from '@/api/v1/ledger_codes'
import { mapMultiRowFields } from 'vuex-map-fields'

export default {
  name: 'ProperSettings',
  data () {
    return {
      filterText: '',
      excelCopy: '',
      result: '',
    }
  },
  computed: {
    ...mapState('ledgerCodes', [
      'ledgerCodes',
    ]),
    ...mapState('properSettings', {
      stateProperSettings: 'properSettings',
    }),
    ...mapMultiRowFields('properSettings', [
      'properSettings',
    ]),
    accountingList () {
      return this.ledgerCodes.filter(ledgerCode => ledgerCode.codeType === 'accounting_type').map(ledgerCode => ledgerCode.codeName)
    },
    filteredProperSettings () {
      if (this.filterText) {
        return this.properSettings.filter(properSetting => properSetting.name.includes(this.filterText))
      } else {
        return this.properSettings
      }
    },
  },
  methods: {
    ...mapActions('ledgerCodes', [
      'getLedgerCodes',
    ]),
    ...mapActions('properSettings', [
      'getProperSettings',
      'patchProperSetting',
    ]),
    getAccountingTypes () {
      LedgerCodeApi.getLedgerCodes({search_type: 'accounting_type'}).then(resp => {
        resp.forEach((ledger_code) => { this.accountingList.push(ledger_code.code_name) })
      })
    },
    uploadProperSetting (properSetting) {
      this.patchProperSetting(properSetting.id)
    },
    uploadAllProperSetting () {
      this.properSettings.forEach( properSetting => {
        this.uploadProperSetting (properSetting)
      })
    },
  },
  mounted () {
    this.getLedgerCodes('all')
    this.getProperSettings ()
  },
}
</script>
