<template>
  <div class='flex mt-4'>
    <div class='px-16'>
      <div class='flex flex-row my-1'>
        <h4>조기상환청구권 Call</h4>
        <button
          class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          name='update-call-data-form-btn'
          @click='addNewOption("call")'>
          <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
        </button>
      </div>

      <table class='mt-4 text-sm'>
        <thead>
          <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
            <th class='px-2 py-2 text-center border-r border-l'>시작일자</th>
            <th class='px-2 py-2 text-center border-r'>종료일자</th>
            <th class='px-2 py-2 text-center border-r'>행사일자</th>
            <th class='px-2 py-2 text-center border-r'>조기상환율</th>
            <th class='px-2 py-2 text-center border-r'>가능비중</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(call, index) in iCallRec'
            :key='`call-${call.id}-${index}`'>
            <td class='px-2 text-left border-l border-r'>
              <input type='text' v-model='call.start_date' class='text-base text-left w-32'
                @paste.prevent='onPasteOption($event, index, iCallRec)'
              />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='call.end_date' class='text-base text-left w-32' />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='call.event_date' class='text-base text-left w-32' />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='call.interest_rate' class='text-base text-right w-16' />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='call.conversion_limit' class='text-base text-right w-16' />
            </td>
          </tr>
        </tbody>
        <tfoot><tr><td colspan='100' class='text-right py-4'>
          <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
            @click='uploadOption("call")'>Save Changes</button></td></tr>
        </tfoot>
      </table>
    </div>

    <div class=''>
      <div class='flex flex-row my-1'>
        <h4>조기상환청구권 Put</h4>
        <button
          class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          name='update-put-data-form-btn'
          @click='addNewOption("put")'>
          <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
        </button>
      </div>

      <table class='mt-4 text-sm px-4'>
        <thead>
          <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
            <th class='px-2 py-2 text-center border-r border-l'>시작일자</th>
            <th class='px-2 py-2 text-center border-r'>종료일자</th>
            <th class='px-2 py-2 text-center border-r'>행사일자</th>
            <th class='px-2 py-2 text-center border-r'>조기상환율</th>
            <th class='px-2 py-2 text-center border-r'>가능비중</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(put, index) in iPutRec'
            :key='`put-${put.id}-${index}`'>

            <td class='px-2 text-left border-l border-r'>
              <input type='text' v-model='put.start_date' class='text-base text-left w-32'
                @paste.prevent='onPasteOption($event, index, iPutRec)'
              />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='put.end_date' class='text-base text-left w-32' />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='put.event_date' class='text-base text-left w-32' />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='put.interest_rate' class='text-base text-right w-16' />
            </td>
            <td class='px-2 border-r'>
              <input type='text' v-model='put.conversion_limit' class='text-base text-right w-16' />
            </td>
          </tr>
        </tbody>
        <tfoot><tr><td colspan='100' class='text-right py-4'>
          <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
            @click='uploadOption("put")'>Save Changes</button></td></tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState, mapGetters }  from 'vuex'
import { PlusCircleIcon }                    from '@vue-hero-icons/outline'
import cloneDeep                             from 'lodash/cloneDeep'
import CopyPasteHelpers                      from '@/utils/copy-paste-helpers'
import optionSchedulesApi                    from '@/api/v1/option_schedules'


export default {
  name: 'Options',
  components: {
    PlusCircleIcon,
  },
  data () {
    return {
      iCallRec: [],
      iPutRec: [],
    }
  },
  watch: {
    'drilldownListingId': {
      handler: function () {
        if (this.drilldownListingId > 0) {
          this.getOptions ()
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('listings', [
      'callSchedules',
      'putSchedules',
    ]),
    ...mapGetters('listings', [
      'drilldownListingId',
      'drilldownListingShareCode',
    ]),
  },
  methods: {
    ...mapActions('listings', [
      'getConversionRightSchedules',
    ]),
    addNewOption (type) {
      if (type === 'call') {
        this.iCallRec.push({ id: 0 })
      } else {
        this.iPutRec.push({ id: 0 })
      }
    },
    getOptions () {
      this.getConversionRightSchedules ({ share_code: this.drilldownListingShareCode }).then ( () => {
        this.iCallRec = cloneDeep(this.callSchedules)
        this.iPutRec = cloneDeep(this.putSchedules)
      })
    },
    uploadOption (type) {
      if (this.drilldownListingId <= 0) {
        alert ('종목코드를 선택해야 합니다.')
      } else {
        if (type === 'call') {
          optionSchedulesApi.postConversionRightSchedule({share_code: this.drilldownListingShareCode, option_type: 'CALL OPTION', options: this.iCallRec}).then(resp => {
            this.getOptions()
            console.log(resp)
          })
        } else {
          optionSchedulesApi.postConversionRightSchedule({share_code: this.drilldownListingShareCode, option_type: 'PUT OPTION', options: this.iPutRec}).then(resp => {
            this.getOptions()
            console.log(resp)
          })
        }
      }
    },
    onPasteOption (data, targetIndex, targetArray) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      var remainLength = targetArray.length - targetIndex

      var ii
      for (ii = 0; ii <= pastedArray.length - remainLength; ii++) {
        targetArray.push({})
      }

      pastedArray.forEach((row, index) => {
        this.updateTargetRow(targetArray[targetIndex + index], row)
      })

      if (ii > 0) {
        targetArray.pop()
      }
    },
    updateTargetRow (targetRow, pastedRow) {
      var index = 0

      targetRow.start_date = pastedRow[index++]
      targetRow.end_date = pastedRow[index++]
      targetRow.event_date = pastedRow[index++]
      targetRow.interest_rate = pastedRow[index++]
      targetRow.conversion_limit = pastedRow[index++]
    },
  },
}
</script>
