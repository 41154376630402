<template>
  <section class='overflow-x-scroll'>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <h4>신청정보</h4>
      <label class='px-8 text-gray-600'>하이일드 포함
        <input type='checkbox' class='form-checkbox' v-model='isHighYeildVisible'>
      </label>
      <label class='px-8 text-gray-600'>기안문 벤처수량 포함
        <input type='checkbox' class='form-checkbox' v-model='isViewRatio'>
      </label>
      <label class='px-8 text-gray-600'>신구주분리
        <input type='checkbox' class='form-checkbox' v-model='isNewNOldStocks'>
      </label>
    </div>
    <table class='ipo-table'>
      <thead>
        <tr class='border-b '>
          <th class=''>계좌구분</th>
          <th class=''>증권사</th>
          <th class=''>계좌번호</th>
          <th class=''>수요예측일</th>
          <th class=''>청약일</th>
          <th class='text-right border-l'>참여단가</th>
          <th class='text-right'>참여수량</th>
          <th class=''>확약여부</th>
          <th class='text-right'>확약기간</th>
          <th class='text-right'>해제일</th>
          <th class='text-right border-l'>배정단가</th>
          <th class='text-right'>배정수량</th>
          <th class='text-right'>청약수수료</th>
          <th class=''></th>
        </tr>
      </thead>
      <tbody class=''>
        <ipo-summary-row v-for='summary in filteredSummaries'
          :key='summary.id'
          :is-view-ratio='isViewRatio'
          :is-new-n-old-stocks='isNewNOldStocks'
          :summary='summary' />
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapMultiRowFields } from 'vuex-map-fields'
import IpoSummaryRow from '@/views/ipo/IpoSummaryRow.vue'

export default {
  name: 'IpoSummaries',
  components: {
    IpoSummaryRow,
  },
  data () {
    return {
      isHighYeildVisible: false,
      isViewRatio: false,
      isNewNOldStocks: false,
    }
  },
  computed: {
    ...mapMultiRowFields('ipos', [
      'ipoSummaries',
    ]),
    filteredSummaries () {
      let summaries = this.ipoSummaries.filter(summary => summary.account_type !== '고유')
      return this.isHighYeildVisible ? summaries : summaries.filter(summary => summary.account_type !== '하이일드')
    }
  },
}
</script>
