<template>
  <tr>
    <td>{{selectedIpo.subscription_date}}</td>
    <td>{{result.fund_short_name}}</td>
    <td>TFIM_TMS</td>
    <td>A{{selectedIpo.share_code}}</td>
    <td></td>
    <td>{{limit}}</td>
    <td>TRUE</td>
    <td class='text-right'>{{numberStyle(quantity)}}</td>
    <td class='text-right'>{{numberStyle(price)}}</td>
    <td class='text-right'>{{numberStyle(0)}}</td>
    <td class='text-right'>{{numberStyle(0)}}</td>
    <td>CorpAct</td>
    <td>{{strategy}}</td>
    <td>Manual</td>
    <td>F</td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IpoResultTransferRow',
  props: [
    'result',
    'isNewShares',
    'isWhole',
  ],
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    limit () {
      if (this.result.account_type === '하이일드') {
        return 'None'
      }
      else if (this.result.ipo_summary.is_lock_up) {
        return this.isNewShares ? 'PV' : 'P'
      }
      else if (this.result.ipo_summary.is_listing_transfer) {
        return this.isNewShares ? 'TV' : 'T'
      }
      else {
        return this.isNewShares ? 'V' : 'None'
      }
    },
    quantity () {
      return this.isNewShares ? this.result.new_allocated_quantity : this.result.old_allocated_quantity
    },
    price () {
      return this.result.allocated_price * (100 + this.result.ipo_summary.subs_fee_rate) / 100
    },
    strategy () {
      return this.isWhole ? 'Whole' : this.result.strategy
    },
  },
  methods: {
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : 0
    },
  }
}
</script>

<style lang='scss' scoped>
  td {
    @apply tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }

</style>
