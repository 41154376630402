<template>
  <section class='overflow-x-scroll py-8'>
    <div class='flex flex-row justify-start gap-x-4 items-stretch'>
      <button class='flex flex-row justify-start gap-x-1 items-center py-2' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4' />
        투자일정
      </button>
      <button
        @click='copyToClipboard'
        class='pl-3 pr-4 flex flex-row justify-start gap-x-0.5 items-center py-2 rounded-md text-sm text-gray-700 border border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'>
        <clipboard-copy-icon class='h-4'/>
        복사
      </button>
    </div>
    <table v-if='showDetails'
      class='ipo-table'>
      <thead>
        <tr>
          <th>투자상품</th>
          <th>납입/수령</th>
          <th>내용</th>
          <th>일자</th>
        </tr>
      </thead>
      <tbody>
        <tr class='border-t'>
          <td>{{selectedIpo.name}}</td>
          <td>기타</td>
          <td>
            <select v-model='selectedInvestmentType'
              class='form-select w-50'
              @change='updateUserDetail("투자내용")'>
              <option v-for='type in selectableInvestmentTpyes'
                :key='type'
                :value='type'>
                {{type}}
              </option>
            </select>
          </td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 10rem'
              @change='updateUserDetail("투자일자")'
              v-model='investmentDate'>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon, ClipboardCopyIcon, ExternalLinkIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoInvestmentSchedule',
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    ClipboardCopyIcon,
    ExternalLinkIcon,
  },
  data () {
    return {
      selectableInvestmentTpyes: [
        '공모주청약',
        '공모주상장',
      ],
      selectedInvestmentType: '',
      showDetails: false,
      notEditing: false,
      investmentDate: '',
    }
  },
  watch: {
    'selectedIpo.details': {
      handler: function (newVal) {
        if (newVal) {
          this.selectedInvestmentType = this.selectedIpo.details &&
                                        this.selectedIpo.details[this.currentIpoDetailsName] &&
                                        this.selectedIpo.details[this.currentIpoDetailsName]['투자내용'] ? this.selectedIpo.details[this.currentIpoDetailsName]['투자내용'] : ''
          this.investmentDate = this.selectedIpo.details &&
                                        this.selectedIpo.details[this.currentIpoDetailsName] &&
                                        this.selectedIpo.details[this.currentIpoDetailsName]['투자일자'] ? this.selectedIpo.details[this.currentIpoDetailsName]['투자일자'] : ''
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    ...mapGetters('users', [
      'currentIpoDetailsName',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    hasSelectedIpo () {
      return Object.keys(this.selectedIpo).length > 0
    },
  },
  methods: {
    ...mapActions ('ipos', [
      'updateIpoDetail',
      'patchIpoDetails',
    ]),
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    copyToClipboard () {
      let result = [
        this.selectedIpo.name,
        '기타',
        this.selectedInvestmentType,
        this.investmentDate
      ].join('\t')
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: '클립보드에 복사 됨.',
        })
      })
    },
    updateUserDetail (key) {
      if (event) {
        this.updateIpoDetail( {id: this.selectedIpo.id, currentIpoDetailsName: this.currentIpoDetailsName, key: key, type: 'text', value: event.target.value} ).then(() => {
          this.patchIpoDetails(this.selectedIpo)
        })
      }
    },
  }
}
</script>
