<template>
  <div class='px-2 lg:px-8 py-4'>
    <div class='mt-2 lg:mt-6'>
      <div class='flex flex-row justify-start items-center gap-x-2'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide mr-4'>섹터</h1>
        <button
          class='border rounded-md border-gray-200 px-2 py-2 lg:py-3 flex flex-row justify-center items-center text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='addNewSectorLarge'>
          <plus-circle-icon class='h-4' />
          Add
        </button>
        <button 
          class='border rounded-md border-gray-200 px-2 py-2 lg:py-3 flex flex-row justify-center items-center text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='getAllLedgerCode("sector_large")'>
          <refresh-cw-icon class='h-4' />
        </button>
      </div>
      <table class='mt-4 text-sm w-full lg:w-auto'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b border-t'>
            <th class='px-2 py-2 bg-gray-100 text-center w-1/2 lg:w-64 border-r border-l'>섹터대분류</th>
            <th class='px-2 py-2 bg-gray-100 text-center w-1/2 lg:w-64 border-r'>섹터소분류</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(sector_large, index) in sector_larges'
            :key='`sector_large-${sector_large.id}-${index}`'
            class='border-b'>
            <td class='border-r border-l'>
              <input type='text' v-model='sector_large.code_name' class='form-input w-full text-left'
                @paste.prevent='onPasteCodes($event, index, sector_larges, "sector")' />
            </td>
            <td class='text-left border-r'>
              <input type='text' v-model='sector_large.sub_names' class='form-input text-left' style='width: 50rem;' />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if='!sector_larges.length' 
        class='w-full max-w-lg bg-gray-100 py-16 text-center'>
        No codes yet.
      </div>
      <button v-else
        class='mt-4 px-2 lg:px-4 py-2 lg:py-3 font-semibold text-right border rounded-md border-gray-200 hover:border-blue-300 opacity-90 hover:opacity-100 hover:shadow-md text-xs uppercase bg-gray-900 text-white'
        @click='uploadLedgerCode("sector_large")'>
        Save Changes
      </button>
    </div>
    <div class='mt-4 lg:mt-12'>
      <div class='flex flex-row justify-start items-center gap-x-2'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide mr-4'>전략</h1>
        <button
          class='px-2 py-2 lg:py-3 border rounded-md border-gray-200 flex flex-row justify-center items-center text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='addNewStrategy'>
          <plus-circle-icon class='h-4' />
          Add
        </button>
        <button 
          class='px-2 py-2 lg:py-3 border rounded-md border-gray-200 flex flex-row justify-center items-center text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='getAllLedgerCode("strategy")'>
          <refresh-cw-icon class='h-4' />
        </button>
      </div>

      <table class='mt-4 text-sm w-full lg:w-auto'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b border-t'>
            <th class='px-2 py-2 bg-gray-100 text-center w-1/2 lg:w-64 border-r border-l'>전략명</th>
            <th class='px-2 py-2 bg-gray-100 text-center w-1/2 lg:w-64 border-r'>참고사항</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(strategy, index) in strategies'
            :key='`strategy-${strategy.id}-${index}`'
            class='border-b'>
            <td class='text-left border-r border-l'>
              <input type='text' v-model='strategy.code_name' class='form-input w-full text-left'
                @paste.prevent='onPasteCodes($event, index, strategies, "strategy")' />
            </td>
            <td class='text-left border-r'>
              <input type='text' v-model='strategy.code_note' class='form-input w-full text-left' />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if='!strategies.length' 
        class='w-full max-w-lg bg-gray-100 py-16 text-center'>
        No codes yet.
      </div>
      <button v-else
        class='mt-4 px-2 lg:px-4 py-2 lg:py-3 font-semibold text-right border rounded-md border-gray-200 hover:border-blue-300 opacity-90 hover:opacity-100 hover:shadow-md text-xs uppercase bg-gray-900 text-white'
        @click='uploadLedgerCode("strategy")'>
        Save Changes
      </button>
    </div>
  </div>
</template>


<script>
import LedgerCodeApi       from '@/api/v1/ledger_codes'
import CopyPasteHelpers    from '@/utils/copy-paste-helpers'
import { PlusCircleIcon }  from '@vue-hero-icons/outline'
import { RefreshCwIcon }   from 'vue-feather-icons'

export default {
  name: 'LedgerCodeEdit',
  components: {
    PlusCircleIcon,
    RefreshCwIcon,
  },
  data () {
    return {
      sector_larges: [],
      strategies: [],
    }
  },
  methods: {
    onPasteCodes (data, targetIndex, targetArray, type) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      var remainLength = targetArray.length - targetIndex

      var ii
      for (ii = 0; ii <= pastedArray.length - remainLength; ii++) {
        targetArray.push({id: 0})
      }

      pastedArray.forEach((row, index) => {
        this.updateTargetRow(targetArray[targetIndex + index], row, type)
      })
    },
    updateTargetRow (targetRow, pastedRow, type) {
      var index = 0

      targetRow.code_name = pastedRow[index++]
      if (type === 'sector') {
        targetRow.sub_names = pastedRow[index++]
      } else {
        targetRow.code_note = pastedRow[index++]
      }
    },
    addNewSectorLarge () {
      this.sector_larges.push({id: 0, code_name: '', sub_names: '', code_note: ''})
    },
    addNewStrategy () {
      this.strategies.push({id: 0, code_name: '', sub_names: '', code_note: ''})
    },
    getAllLedgerCode (type) {
      LedgerCodeApi.getLedgerCodes({search_type: type}).then(resp => {
        if (type === 'all' || type === 'sector_large') {
          this.sector_larges = resp.filter((ledger_code) => ledger_code.code_type === 'sector_large')
        }
        if (type === 'all' || type === 'strategy') {
          this.strategies = resp.filter((ledger_code) => ledger_code.code_type === 'strategy')
        }
      })
    },
    uploadLedgerCode (code_type) {
      if (code_type === 'sector_large') {
        LedgerCodeApi.postLedgerCodes({code_type: code_type, ledger_codes: this.sector_larges}).then((resp) => {
          this.getAllLedgerCode(code_type)
          alert(resp)
        })
      } else {
        LedgerCodeApi.postLedgerCodes({code_type: code_type, ledger_codes: this.strategies}).then((resp) => {
          this.getAllLedgerCode(code_type)
          alert(resp)
        })
      }
    },
  },
  mounted () {
    this.getAllLedgerCode('all')
  },
}
</script>
