<template>
  <div>
    <div class='flex flex-row my-1'>
      <h3 class='p-2 text-xl'>거래내역</h3>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='update-order-data-form-btn'
        @click='addNewOrder'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
      <button class='px-4 font-bold border rounded-md border-transparent hover:border-gray-500' @click='refreshOrder'>Refresh</button>
    </div>

    <table class='mt-4 text-sm'>
      <thead>
        <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
          <th @click='sortOrders("id")' class='px-2 py-2 cursor-pointer text-left border-r border-l border-t'>ID</th>
          <th @click='sortOrders("created_on")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>매매일</th>
          <th @click='sortOrders("short_name")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>펀드</th>
          <th @click='sortOrders("share_code")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>자산코드</th>
          <th @click='sortOrders("name")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>자산명</th>
          <th @click='sortOrders("investment_id")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>투자번호</th>
          <th @click='sortOrders("limit_order")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>제한</th>
          <th @click='sortOrders("action")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>매수</th>
          <th @click='sortOrders("quantity")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>수량</th>
          <th @click='sortOrders("price")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>단가</th>
          <th @click='sortOrders("fee")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>수수료</th>
          <th @click='sortOrders("tax")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>세금</th>
          <th @click='sortOrders("transaction_value")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>금액</th>
          <th @click='sortOrders("transaction_net_value")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>처리금액</th>
          <th @click='sortOrders("dividend")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>현금배당</th>
          <th @click='sortOrders("strategy")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>전략</th>
          <th @click='sortOrders("settlement_date")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>결제일</th>
          <th @click='sortOrders("trade_type")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>거래방법</th>
          <th @click='sortOrders("principal_type")' class='px-2 py-2 cursor-pointer text-left border-r border-t'>원금구분</th>
          <th @click='clickCheck("check_schedule")' class='bg-blue-100 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l'>투자일정</th>
          <th @click='clickCheck("check_filled_wh")' class='bg-blue-100 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l'>체결처리</th>
          <th @click='clickCheck("is_cancel")' class='bg-blue-100 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l'>거래취소</th>
          <th @click='clickCheck("check_filled_st")' class='bg-blue-100 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l'>TMS 체결전송</th>
          <th @click='clickCheck("check_dividend_st")' class='bg-blue-100 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l'>TMS 배당전송</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(order, index) in orders'
          @click='selectOrder(index)'
          :class='highlightRow(index)'
          :key='`order-${order.id}`'>
          <td class='px-2 py-2 text-left border-r border-l'>{{ order.id }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.created_on }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.short_name }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.share_code }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.name}}</td>
          <td class='px-2 py-2 text-right border-r'>{{ order.investment_id }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.limit_order}}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.action_name}}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.quantity) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.price) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.fee) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.tax) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.transaction_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.transaction_net_value) }}</td>
          <td class='px-2 py-2 text-right border-r'>{{ toNumber(order.dividend) }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.strategy }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.settlement_date }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.trade_type }}</td>
          <td class='px-2 py-2 text-left border-r'>{{ order.principal_type }}</td>
          <td class='text-center border-r'>
            <input type='checkbox' :disabled='order.is_cancel' class='form-checkbox' v-model='order.check_schedule' @change='changeOrders(order)'>
          </td>
          <td class='text-center border-r'>
            <input type='checkbox' :disabled='order.is_cancel' class='form-checkbox' v-model='order.check_filled_wh' @change='changeOrders(order)'>
          </td>
          <td class='text-center border-r'>
            <input type='checkbox' class='form-checkbox' v-model='order.is_cancel' @change='changeOrders(order)'>
          </td>
          <td class='text-center border-r'>
            <input type='checkbox' :disabled='order.is_cancel' class='form-checkbox' v-model='order.check_filled_st' @change='changeOrders(order)'>
          </td>
          <td class='text-center border-r'>
            <input type='checkbox' :disabled='order.is_cancel' class='form-checkbox' v-model='order.check_dividend_st' @change='changeOrders(order)'>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan='100' class='text-right py-4'>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='selectAll'>전체선택</button>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='resetSelect'>선택초기화</button>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='copyToClipboard'>Copy To Clipboard</button>
            <button class='px-4 font-bold text-right border rounded-md border-transparent hover:border-gray-500'
              @click='sendOrderCheck'>Save Changes</button>
          </td>
        </tr>
      </tfoot>
    </table> 

    <ledger-modal v-if='addOrder'
      @done-editing='doneEdiging'
      :modalName='"order"'
      :dataRec='dataRec'
      :modalStyle='"height: 800px; width: 2000px;"' />

  </div>
</template>

<script>
import { mapState }  from 'vuex'
import LedgerModal              from '@/views/investment_ledger/LedgerModal.vue'
import OrdersApi                from '@/api/v1/orders'
import { PlusCircleIcon }       from '@vue-hero-icons/outline'
import numbro from 'numbro'

export default {
  name: 'Orders',
  components: {
    PlusCircleIcon,
    LedgerModal,
  },
  props: [
    'orders',
    'investmentId',
    'investmentDetailId',
    'investmentName',
    'entityInvestmentSeq',
  ],
  data () {
    return {
      dataRec: {},
      selectedOrder: '',
      addOrder: false,
      sortDirections: {
        created_on: 'asc',
        settlement_date: 'asc',
      },
      sortInfo: {
        key: '',
        direction: ''
      },
      firstIndex: -1,
      secondIndex: -1,
    }
  },
  computed: {
    ...mapState('listings', [
      'drilldownListing',
    ]),
  },
  methods: {
    sendOrderCheck () {
      var changedOrder = this.orders.filter(order => order.is_change)

      OrdersApi.patchOrders(changedOrder).then(resp => {
        alert(resp)
        this.$emit('done-editing')
      })
    },
    highlightRow (index) {
      return index >= this.firstIndex && index <= this.secondIndex ? 'bg-blue-100 border-b' : 'border-b'
    },
    selectOrder (index) {
      if (event.shiftKey) {
        if (this.firstIndex === -1) {
          this.firstIndex = index
          this.secondIndex = index
        } else {
          if (index < this.firstIndex) {
            this.secondIndex = this.firstIndex
            this.firstIndex = index
          } else {
            this.secondIndex = index
          }
        }
      } else {
        this.firstIndex = index
        this.secondIndex = index
      }
    },
    clickCheck (checkKey) {
      this.orders.forEach((order, index) => {
        if (index >= this.firstIndex && index <= this.secondIndex) {
          order[checkKey] = order[checkKey] ? false : true
          order.is_change = order[checkKey] ? true : false
        }
      })
    },
    selectAll () {
      this.firstIndex = 0
      this.secondIndex = this.orders.length - 1
    },
    resetSelect () {
      this.firtIndex = -1
      this.secondIndex = -1
    },
    refreshOrder () {
      this.$emit('done-editing', '')
    },
    addNewOrder () {
      if (!this.drilldownListing || this.drilldownListing.id <= 0) {
        alert('선택된 자산코드가 없습니다.')
      } else if (!this.investmentId || this.investmentId <= 0) {
        alert('선택된 투자정보가 없습니다.')
      } else if (!this.investmentDetailId || this.investmentDetailId <= 0) {
        alert('선택된 투자정보가 없습니다.')
      } else {
        this.dataRec.investmentId = this.investmentId
        this.dataRec.investmentDetailId = this.investmentDetailId
        this.dataRec.investmentName = this.investmentName
        this.dataRec.entityInvestmentSeq = this.entityInvestmentSeq
        this.addOrder = true
      }
    },
    doneEdiging () {
      this.addOrder = false
      this.$emit('done-editing')
    },
    toNumber (number) {
      if (number) {
        return numbro(number).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    changeOrders (order) {
      order.is_change = true
    },
    copyToClipboard () {
      let string = ''
      string = this.tableHeaderString ()
      this.orders.forEach((order, index) => {
        if (index >= this.firstIndex && index <= this.secondIndex)
          string += this.convertDataToString(order)
      })
      this.$copyText(string)
    },
    tableHeaderString () {
      let string = ''
      string += 'ID' + '\t'
      string += '매매일' + '\t'
      string += '펀드' + '\t'
      string += '자산코드' + '\t'
      string += '자산명' + '\t'
      string += '투자번호' + '\t'
      string += '제한' + '\t'
      string += '매매구분' + '\t'
      string += '수량' + '\t'
      string += '단가' + '\t'
      string += '수수료' + '\t'
      string += '세금' + '\t'
      string += '현금배당' + '\t'
      string += '금액' + '\t'
      string += '전략' + '\t'
      string += '결제일' + '\t'
      string += '거래방법' + '\t'
      string += '원금구분'
      string += '\n'

      return string
    },
    convertDataToString(order) {
      let string = ''
      string += order.id + '\t'
      string += order.created_on + '\t'
      string += order.short_name + '\t'
      string += order.share_code + '\t'
      string += order.name + '\t'
      string += order.investment_id + '\t'
      string += order.limit_order + '\t'
      string += order.action_ame + '\t'
      string += order.quantity + '\t'
      string += order.price + '\t'
      string += order.fee + '\t'
      string += order.tax + '\t'
      string += order.dividend + '\t'
      string += order.transaction_value + '\t'
      string += order.strategy + '\t'
      string += order.settlement_date + '\t'
      string += order.trade_type + '\t'
      string += order.principal_type
      string += '\n'

      return string
    },
    sortOrders (key) {
      this.sortInfo.direction = this.toggleSortDirection(key)
      this.sortInfo.key = key
      this.sortData(this.orders, this.sortInfo)
    },
    toggleSortDirection (key) {
      if (!this.sortDirections[key]) {
        this.sortDirections[key] = 'desc'
      }
      if (this.sortDirections[key] === 'asc') {
        this.sortDirections[key] = 'desc'
      } else {
        this.sortDirections[key] = 'asc'
      }
      return this.sortDirections[key]
    },
    sortData (orders, sortInfo) {
      orders.sort((aOrder, bOrder) => {
        let aValue, bValue
        if (typeof aOrder === 'string') {
          aValue = aOrder[sortInfo.key].toUpperCase()
          bValue = bOrder[sortInfo.key].toUpperCase()
        } else {
          aValue = aOrder[sortInfo.key]
          bValue = bOrder[sortInfo.key]
        }

        if (sortInfo.direction === 'asc') {
          if (aValue < bValue) {
            return -1
          } else if (aValue > bValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (aValue < bValue) {
            return 1
          } else if (aValue > bValue) {
            return -1
          } else {
            return 0
          }
        }
      })
    },
  },
}
</script>
