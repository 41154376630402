<template>
  <tr v-show='isVisible'>
    <td>
      <span v-if='isRoot'
        class='py-2 pl-2 pr-3' @click='toggleDetails'>
        <component v-if='isRoot' :is='toggleIcon' class='h-4 inline-block cursor-pointer' />
        {{ brokerName }}
      </span>
      <span v-else
        class='py-2 pl-12 pr-3'>
        {{ brokerName }}
      </span>
    </td>
    <td>{{ accountNumber }}</td>
    <td>{{ brokerContactEmail }}</td>
    <td>{{ brokerContactNumber }}</td>
    <td>{{ brokerNote }}</td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoBrokerAccountRow',
  props: {
    brokerAccount: Object,
    isRoot: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    styleDependingOnDetails () {
      return this.isRoot ? 'pl-2' : 'pl-16'
    },
    brokerName () {
      return this.isRoot ? this.brokerAccount.broker_name : this.brokerAccount.account_type
    },
    accountType () {
      return this.isRoot ? '' : this.brokerAccount.account_type
    },
    accountNumber () {
      return this.isRoot ? '' : this.brokerAccount.broker_account
    },
    brokerNote () {
      return this.isRoot ? this.brokerAccount.note : ''
    },
    brokerContactEmail () {
      return this.isRoot ? this.brokerAccount.contact_email : ''
    },
    brokerContactNumber () {
      return this.isRoot ? this.brokerAccount.contact_number : ''
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    toggleDetails () {
      this.showDetails = !this.showDetails
      if (!this.showDetails) {
        this.sidepanelClose()
      }
      this.$emit('update:toggleDetails', this.showDetails)
    },
  }
}
</script>
