<template>
  <div>
    <div class='overflow-x-scroll  mt-5'>
      <div class='flex flex-row my-6'>
        <h3 class='text-xl p-2'>종목정보</h3>
        <button
          class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          name='update-listing-form-btn'
          @click='updateListing("insert")'>
          <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
        </button>
        <div class='relative'>
          <input class='form-input' type='text' placeholder='종목코드/종목명' v-model='searchText' @keyup.enter='refreshSearch' />
        </div>
      </div>
      <table class='text-sm'>
        <thead>
          <tr class='bg-gray-200 text-left uppercase tracking-wide border-b'>
            <th class='px-2 py-2 text-left border-r border-l border-t'>선택</th>
            <th class='px-2 py-2 text-left border-r border-t'>종목코드</th>
            <th class='px-2 py-2 text-left border-r border-t'>종목명</th>
            <th class='px-2 py-2 text-left border-r border-t'>펀드구분</th>
            <th class='px-2 py-2 text-left border-r border-t'>펀드형태</th>
            <th class='px-2 py-2 text-left border-r border-t'>기업코드</th>
            <th class='px-2 py-2 text-left border-r border-t'>기초자산명</th>
            <th class='px-2 py-2 text-left border-r border-t'>펀드결성일</th>
            <th class='px-2 py-2 text-left border-r border-t'>펀드약정총액</th>
            <th class='px-2 py-2 text-left border-r border-t'>1좌금액</th>
            <th class='px-2 py-2 text-left border-r border-t'>만기연장조건</th>
            <th class='px-2 py-2 text-left border-r border-t'>기준수익률(%)</th>
            <th class='px-2 py-2 text-left border-r border-t'>성과보수율(%)</th>
            <th class='px-2 py-2 text-left border-r border-t'>관리보수율(%)</th>
            <th class='px-2 py-2 text-left border-r border-t'>관리보수기준</th>
            <th class='px-2 py-2 text-right border-r border-t'>구주/신주</th>
            <th class='px-2 py-2 text-left border-r border-t'>투자단가</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='listing in listings'
            :key='`listing-${listing.id}`'
            @click='selectListing(listing)'
            @dblclick='updateListing("update")'
            :class='highlightRow(listing.id)'>
            <td class='px-2 py-2 text-left border-r border-l'>
              <input type='checkbox' disabled class='form-checkbox' v-model='listing.check'>
            </td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.fund_type }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.fund_form }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.company_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.base_asset_name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.fund_start_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.fund_contract_value }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.fund_share_value }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.fund_delay_condition }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.base_earning_rate }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.carried_interest_rate }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.management_interest_rate }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.management_interest_standard }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ listing.old_or_new }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ listing.investment_price }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ledger-modal v-if='editListing' @done-editing='doneEditListing' :modalName='"fundListing"' :modalStyle='"height: 800px; width: 1000px;"' />

  </div>
</template>


<script>
import { mapActions, mapState, mapGetters }  from 'vuex'
import LedgerModal                       from '@/views/investment_ledger/LedgerModal.vue'
import { PlusCircleIcon }                    from '@vue-hero-icons/outline'
import numbro                                from 'numbro'
import ListingsApi                           from '@/api/v1/listings'
import CopyPasteHelpers                      from '@/utils/copy-paste-helpers'
import cloneDeep                             from 'lodash/cloneDeep'


export default {
  name: 'FundListings',
  components: {
    PlusCircleIcon,
    LedgerModal,
  },
  data () {
    return {
      searchText: '',
      editListing: false,
      iDateRecs: [{}],
      listing: {},
      isDirect: false,
    }
  },
  watch: {
    'drilldownEntityId': {
      handler: function () {
        this.getEntityListings ({ entity_id: this.drilldownEntityId, is_direct: this.isDirect })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('listings', [
      'listings',
      'drilldownListing',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityName',
      'drilldownEntityId',
    ]),
  },
  methods: {
    ...mapActions('listings', [
      'getListings',
      'getEntityListings',
      'setSelectedListing',
      'resetDrilldownListing',
    ]),
    highlightRow (id) {
      return id === this.drilldownListing.id ? 'bg-gray-200 text-gray-900 font-semibold' : ''
    },
    addNewDate () {
      this.iDateRecs.push({index: this.iDateRecs.length, iDate: ''})
    },
    selectListing (listing) {
      this.setSelectedListing(listing)
      this.iDateRecs = []
      this.drilldownListing.interest_dates.forEach((iDate,index) => {
        this.iDateRecs.push({index: index, iDate: iDate})
      })
      this.listings.forEach((listing) => listing.check = false)
      listing.check = true
    },
    refreshSearch () {
      this.getListings({ search_text: this.searchText, is_direct: this.isDirect })
    },
    updateListing (type) {
      if (type === 'insert') {
        this.resetDrilldownListing().then ( () => {
          this.editListing = true
        })
      } else {
        this.editListing = true
      }
    },
    doneEditListing () {
      this.editListing = false
      if (this.searchText.length > 0) {
        this.refreshSearch ()
      } else {
        this.getEntityListings ({ entity_id: this.drilldownEntityId, is_direct: this.isDirect })
      }
    },
    toNumber (number) {
      return numbro(number).format({ thousandSeparated: true })
    },
    onPasteiDate (data, iDateIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)
      let iDateLength = this.iDateRecs.length

      pastedArray.forEach((row, index) => {
        if (index + iDateIndex >= iDateLength) {
          this.iDateRecs.push({index: index + iDateIndex, iDate: row[0]})
        } else {
          this.iDateRecs[index + iDateIndex].iDate = row[0]
        }
      })
    },
    updateInterestDate () {
      if (this.drilldownListing.id > 0) {
        this.listing = cloneDeep(this.drilldownListing)
        this.listing.interest_dates = []
        this.iDateRecs.forEach(iDateRec => {
          if (iDateRec.iDate.length === 10) {
            this.listing.interest_dates.push(iDateRec.iDate)
          }
        })
        ListingsApi.patchListing(this.drilldownListing.id, this.listing).then(resp => {
          this.setSelectedListing(resp)
          if (this.searchText.length > 0) {
            this.refreshSearch ()
          } else {
            this.getEntityListings ({ entity_id: this.drilldownEntityId, is_direct: this.isDirect })
          }
        })
      }
    },
  },
}
</script>
