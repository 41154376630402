<template>
  <section v-if='hasIpoResults' class='overflow-x-scroll py-8'>
    <h4>
      <span class='py-2 cursor-pointer' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4 inline-block inline-block' />
        펀드별 배정수량
      </span>
    </h4>
    <table v-if='showDetails' class='text-sm table-fixed'>
      <thead>
        <tr>
          <th>펀드코드</th>
          <th>배정수량</th>
        </tr>
      </thead>
      <tbody v-for='result in filteredResults' :key='`result-${result.id}`'>
        <td>{{result.fund_code}}</td>
        <td>{{result.total_allocated_quantity}}</td>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoResultFunds',
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'ipoResults',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    filteredResults () {
      return this.ipoResults.filter(result => result.total_allocated_quantity > 0)
    },
    hasIpoResults () {
      return this.filteredResults.length > 0
    },
  },
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
  td {
    @apply uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }

</style>
