<template>
  <div class='pb-8 px-16 mt-4'>
    <div class='flex flex-row my-1 py-2'>
      <input class='form-input' type='text' placeholder='자산명' v-model='filterText' @keyup.enter='getPortfolioSectors' />
      <button class='hover:bg-gray-100 rounded-md ml-4 border w-32' @click='getPortfolioSectors'>조회</button>
      <input class='form-input ml-4' type='text' placeholder='Excel Copy' @paste.prevent='onPasteData($event)' v-model='excelCopy' />
      <span class='text-base mt-2 ml-4 w-full' @click='result=""'>{{ result }}</span>
    </div>
    <table class='mt-4 text-sm'>
      <thead>
        <tr class='text-left uppercase tracking-wide border-b border-t'>
          <th class='px-2 py-2 bg-gray-100 text-center border-r border-l'>투자ID</th>
          <th class='px-2 py-2 bg-gray-100 text-center border-r'>자산명</th>
          <th class='px-2 py-2 bg-gray-100 text-center border-r'>전략</th>
          <th class='px-2 py-2 bg-gray-100 text-center border-r'>섹터대분류</th>
          <th class='px-2 py-2 bg-gray-100 text-center border-r'>섹터소분류</th>
          <td class='px-2 py-2 bg-gray-100 text-center border-r'>
            <button class='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
              @click='uploadAllPortfolioSector()'>
              저장
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for='portfolio_sector in portfolio_sectors'
          :key='`portfolio_sector-${portfolio_sector.id}`'>
          <td class='px-2 border-r border-l border-b text-right'>
            {{ portfolio_sector.tms_investment_id }}
          </td>
          <td class='px-2 border-r border-l border-b text-left'>
            {{ portfolio_sector.name }}
          <td class='px-2 border-r border-l'>
            <v-select v-model='portfolio_sector.strategy'
              :options='strategyList'
              label='전략'
              :close-on-select='true'
              @close='portfolio_sector.update="Y"'
              class='w-full'>
            </v-select>
          </td>
          <td class='px-2 border-r border-l'>
            <v-select v-model='portfolio_sector.sector_large'
              :options='sectorLargeList'
              label='섹터대분류'
              :close-on-select='true'
              @close='portfolio_sector.update="Y"'
              class='w-full'>
            </v-select>
          </td>
          <td class='px-2 border-r border-l'>
            <v-select v-model='portfolio_sector.sector_small'
              :options='sectorSmallList(portfolio_sector.sector_large)'
              label='섹터소분류'
              :close-on-select='true'
              @close='portfolio_sector.update="Y"'
              class='w-full'>
            </v-select>
          </td>
          <td class='text-center'>
            <button class='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
              @click='uploadPortfolioSector(portfolio_sector)'>
              저장
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import PortfolioSectosApi          from '@/api/v1/portfolio_sectors'
import LedgerCodeApi               from '@/api/v1/ledger_codes'
import CopyPasteHelpers            from '@/utils/copy-paste-helpers'


export default {
  name: 'PortfolioSectors',
  data () {
    return {
      filterText: '',
      portfolio_sectors: [],
      strategyList: [],
      sectorList: [],
      sectorLargeList: [],
      excelCopy: '',
      result: '',
    }
  },
  methods: {
    onPasteData (data) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      pastedArray.forEach((dataArray) => {
        var dataIndex = this.portfolio_sectors.findIndex( datum => parseInt(datum.tms_investment_id) === parseInt(dataArray[0]))
        if ( dataIndex >= 0) {
          if (dataArray[1])
            this.portfolio_sectors[dataIndex].strategy = dataArray[1]
           
          if (dataArray[2])
            this.portfolio_sectors[dataIndex].sector_large = dataArray[2]

          if (dataArray[3])
            this.portfolio_sectors[dataIndex].sector_small = dataArray[3]

          this.portfolio_sectors[dataIndex].update = 'Y'
        }
      })
    },
    getPortfolioSectors () {
      PortfolioSectosApi.getPortfolioSectors({filter: this.filterText}).then(resp => {
        this.portfolio_sectors = resp
      })
    },
    getStrategys () {
      LedgerCodeApi.getLedgerCodes({search_type: 'strategy'}).then(resp => {
        resp.forEach((ledger_code) => { this.strategyList.push(ledger_code.code_name) })
      })
    },
    getSectors () {
      LedgerCodeApi.getLedgerCodes({search_type: 'sector_large'}).then(resp => {
        this.sectorList = resp
        this.sectorList.forEach((sector_code) => { this.sectorLargeList.push(sector_code.code_name) })
      })
    },
    uploadPortfolioSector (portfolio_sector) {
      PortfolioSectosApi.patchPortfolioSector(portfolio_sector).then(resp => {
        this.result = resp
      })
    },
    uploadAllPortfolioSector () {
      this.portfolio_sectors.forEach( portfolio_sector => {
        if (portfolio_sector.update && portfolio_sector.update === 'Y')
          this.uploadPortfolioSector (portfolio_sector)
      })
    },
    sectorSmallList (sectorLarge) {
      if (!sectorLarge)
        return []

      let index = this.sectorList.findIndex( (sector_code) => sector_code.code_name === sectorLarge)
      if (index === -1)
        return []

      if (this.sectorList[index].sub_names) {
        return this.sectorList[index].sub_names.split(',')
      } else {
        return []
      }
    },
  },
  mounted () {
    this.getSectors ()
    this.getStrategys ()
  },
}
</script>
