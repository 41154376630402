<template>
  <div>
    <div class='flex flex-row my-1'>
      <h1 class='text-2xl font-semibold px-8 py-8'>거래입력</h1>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='update-order-form-btn'
        @click='addNewOrder'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>

    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-32'>투자회사명</label>
      <span class='w-64'>{{ drilldownEntityName }}</span>
      <label class='px-4 inline-block w-32'>투자명</label>
      <span class='w-64'> {{ dataRec.investmentName }} </span>
      <label class='px-4 inline-block w-32'>투자번호</label>
      <span class='w-32'> {{ dataRec.investmentId }} </span>
      <label class='px-4 inline-block w-32'>투자자산번호</label>
      <span class='w-32'> {{ dataRec.entityInvestmentSeq }} </span>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-32'>종목코드</label>
      <span class='w-64'>{{ drilldownListing.share_code }}</span>
      <label class='px-4 inline-block w-32'>종목명</label>
      <span class='w-64'>{{ drilldownListing.name }}</span>
    </div>

    <table class='table-auto mt-8'>
      <thead>
        <tr class='border-t border-b bg-gray-100'>
          <th class='py-2 px-4 text-center border-r border-l'>Copy</th>
          <th class='py-2 px-4 text-center border-r'>펀드</th>
          <th class='py-2 px-4 text-center border-r'>종목</th>
          <th class='py-2 px-4 text-center border-r'>제한</th>
          <th class='py-2 px-4 text-center border-r'>매수</th>
          <th class='py-2 px-4 text-center border-r'>수량</th>
          <th class='py-2 px-4 text-center border-r'>단가</th>
          <th class='py-2 px-4 text-center border-r'>수수료</th>
          <th class='py-2 px-4 text-center border-r'>세금</th>
          <th class='py-2 px-4 text-center border-r'>현금배당</th>
          <th class='py-2 px-4 text-center border-r'>전략</th>
          <th class='py-2 px-4 text-center border-r'>결제일</th>
          <th class='py-2 px-4 text-center border-r'>거래방법</th>
          <th class='py-2 px-4 text-center border-r'>원금구분</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(order, index) in newOrders' :key='`create-new-order-${index}-${order.id}`' class='border-b'>
          <td class='text-left border-r border-l'>
            <input type='text'
              v-model='order.copyArea'
              class='text-base py-2 px-4 w-full'
              @paste.prevent='onPasteData($event, index)'
            />
          </td>
          <td class='text-left border-r'>
            <v-select
              v-model='order.short_name'
              label='펀드명'
              :options='fundNames'
              :close-on-select='true'
              class='w-32'>
            </v-select>
          </td>
          <td class='text-left border-r'>
            <v-select
              v-model='order.share_code'
              label='종목코드'
              :options='shareCodes'
              :close-on-select='true'
              class='w-40'>
            </v-select>
          </td>
          <td class='text-left border-r'>
            <v-select v-model='order.limit_order'
              :options='limitOrders'
              label='제한'
              :close-on-select='true'
              class='w-48'>
            </v-select>
          </td>
          <td class='text-left border-r'>
            <v-select v-model='order.action_name'
              :options='actions'
              label='매수'
              :close-on-select='true'
              class='w-32'>
            </v-select>
          </td>
          <td class='border-r'>
            <input type='numeric' v-model='order.quantity' class='text-right text-base py-2 px-4 w-32'
              @input='e=>order.quantity=changeNum(e.target.value)'>
          </td>
          <td class='border-r'>
            <input type='numeric' v-model='order.price' class='text-right text-base py-2 px-4 w-32'
              @input='e=>order.price=changeNum(e.target.value)'>
          </td>
          <td class='border-r'>
            <input type='numeric' v-model='order.fee' class='text-right text-base py-2 px-4 w-24'
              @input='e=>order.fee=changeNum(e.target.value)'>
          </td>
          <td class='border-r'>
            <input type='numeric' v-model='order.tax' class='text-right text-base text-right py-2 px-4 w-24'
              @input='e=>order.tax=changeNum(e.target.value)'>
          </td>
          <td class='text-right border-r'>
            <input type='numeric' v-model='order.dividend' class='text-right text-base text-right py-2 px-4 w-24'
              @input='e=>order.dividend=changeNum(e.target.value)'>
          </td>
          <td class='text-left border-r border-l'>
            <input type='text' v-model='order.strategy' class='text-base py-2 px-4 w-24' />
          </td>
          <td class='text-left border-r w-24'>
            <el-date-picker
              v-model='order.settlement_date'
              value-format='yyyy-MM-dd'
              type='date'
              placeholder='결제일 선택'>
            </el-date-picker>
          </td>
          <td class='text-left border-r'>
            <v-select v-model='order.trade_type'
              :options='tradeTypes'
              label='거래방법'
              :close-on-select='true'
              class='w-40'>
            </v-select>
          </td>
          <td class='text-left border-r'>
            <v-select v-model='order.principal_type'
              :options='principalList'
              label='원금구분'
              :close-on-select='true'
              class='w-32'>
            </v-select>
          </td>
        </tr>
      </tbody>
    </table>

    <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
      <button
        class='border px-6 h-12 text-sm text-white rounded-md font-bold'
        style='background-color: #223645;'
        @click='submitOrder'>저장
      </button>
      <span class='font-semibold text-left'>{{ result }}</span>
      <button
        class='border px-6 h-12 text-sm rounded-md font-bold'
        @click='clearOrder'>초기화
      </button>
    </div>

  </div>
</template>


<script>
import { mapState, mapGetters }  from 'vuex'
import OrdersApi                 from '@/api/v1/orders'
import FundsApi                  from '@/api/v1/funds'
import LedgerCodeApi             from '@/api/v1/ledger_codes'
import CopyPasteHelpers          from '@/utils/copy-paste-helpers'
import { PlusCircleIcon }        from '@vue-hero-icons/outline'
import numbro from 'numbro'
import cloneDeep from 'lodash/cloneDeep'
const newOrder =  {
  id: 0, short_name: '', copyArea: '', share_code: '', limit_order: '', action_name: '',
  quantity: 0.0, price: 0.0, fee: 0.0, tax: 0.0, dividend: 0.0, strategy: '',
  settlement_date: '', trade_type: '', principal_type: ''
}

export default {
  name: 'OrderAdd',
  components: {
    PlusCircleIcon,
  },
  props: [
    'dataRec',
  ],
  data () {
    return {
      principalList: ['자전', '투자', '청약', '전환', '단주', '차입', '기초', '약정'],
      actions: ['true', 'false'],
      limitOrders: ['None','유증','락업','확약','메자닌행사','주식배당','분할','이전상장','벤처신주','구벤처CB/BW'],
      fundNames: [],
      newOrders: [cloneDeep(newOrder)],
      tradeTypes: [],
      shareCodes: [],
      value: 0,
      result: 'No result',
    }
  },
  computed: {
    ...mapState('listings', [
      'listings',
      'drilldownListing',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityName',
      'drilldownEntityId',
    ]),
  },
  methods: {
    clearOrder () {
      this.newOrders = []
      this.newOrders.push(cloneDeep(newOrder))
      this.result = 'No Result'
    },
    submitOrder () {
      this.newOrders.forEach(newOrder => {
        newOrder.investment_id = this.dataRec.investmentId
        newOrder.investment_detail_id = this.dataRec.investmentDetailId
      })

      OrdersApi.createOrder(this.newOrders).then(resp => {
        this.result = resp
        this.doneEditing()
      })
    },
    doneEditing () {
      this.$emit('done-editing')
    },
    getFundNames () {
      FundsApi.getFunds().then(resp => {
        resp.forEach(fund => { this.fundNames.push(fund.short_name) })
      })
    },
    getTradeTypes () {
      LedgerCodeApi.getLedgerCodes({search_type: 'trade_type'}).then(resp => {
        resp.forEach((trade_type) => { this.tradeTypes.push(trade_type.code_name) })
      })
    },
    onPasteData (data, targetIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      var remainLength = this.newOrders.length - targetIndex
      var ii

      for (ii = 0; ii <= pastedArray.length - remainLength; ii++) {
        this.newOrders.push(cloneDeep(newOrder))
      }

      pastedArray.forEach((row, index) => {
        this.updateRow(targetIndex + index, row)
      })

      if (ii > 0) {
        this.newOrders.pop()
      }
    },
    updateRow (orderIndex, arrayRow) {
      var index = 0

      this.newOrders[orderIndex].entity_id = this.drilldownEntityId
      this.newOrders[orderIndex].short_name = arrayRow[index++]
      this.newOrders[orderIndex].share_code = arrayRow[index++]
      this.newOrders[orderIndex].limit_order = arrayRow[index++]
      this.newOrders[orderIndex].action_name = arrayRow[index++]
      this.newOrders[orderIndex].quantity = this.changeNum(arrayRow[index++])
      this.newOrders[orderIndex].price = this.changeNum(arrayRow[index++])
      this.newOrders[orderIndex].fee = this.changeNum(arrayRow[index++])
      this.newOrders[orderIndex].tax = this.changeNum(arrayRow[index++])
      this.newOrders[orderIndex].dividend = this.changeNum(arrayRow[index++])
      this.newOrders[orderIndex].strategy = arrayRow[index++]
      this.newOrders[orderIndex].settlement_date = arrayRow[index++]
      this.newOrders[orderIndex].trade_type = arrayRow[index++]
      this.newOrders[orderIndex].principal_type = arrayRow[index++]
    },
    addNewOrder () {
      this.newOrders.push({id: this.newOrders.length})
    },
    changeNum (value) {
      if (value) {
        let strNum = this.uncomma(value)
        let strArr = strNum.split('.')
        let bExists = strNum.indexOf('.')
        let retVal = ''

        if (strArr[0])
          retVal = numbro(strArr[0]).format({ thousandSeparated: true })
        if (bExists > -1)
          retVal += '.'
        if (strArr[1])
          retVal += strArr[1]

        return value = retVal
      } else {
        return value = ''
      }
    },
    uncomma(str) {
      str = String(str)
      return str.replace(/[^(\d.)]+/g, '')
    },
    makeShareCodes () {
      this.listings.forEach((listing) => { this.shareCodes.push (listing.share_code) })
    },
  },
  mounted () {
    this.getFundNames ()
    this.getTradeTypes ()
    this.makeShareCodes ()
  },
}
</script>
