<template>
  <section v-if='hasIpoResults' class='overflow-x-scroll py-8'>
    <h4>
      <span class='py-2 cursor-pointer' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4 inline-block inline-block' />
        계좌별수탁사별 배정수량
      </span>
    </h4>
    <div v-if='showDetails' class='py-2 text-sm flex'>
      <template v-for='accountType in accountTypes'>
        <ipo-result-accounts-row
          v-if='hasGroupByContactName(accountType)'
          :key='accountType'
          :account-type='accountType'
          :accounts='groupByContactName(accountType)' />
      </template>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'
import IpoResultAccountsRow from '@/views/ipo/IpoResultAccountsRow.vue'
import groupBy         from 'lodash/groupBy'

export default {
  name: 'IpoResultAccounts',
  components: {
    IpoResultAccountsRow,
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'accountTypes',
      'ipoResults',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    filteredResults () {
      return this.ipoResults.filter(result => result.total_allocated_quantity > 0)
    },
    hasIpoResults () {
      return this.filteredResults.length > 0
    },
  },
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    groupByContactName (accountType) {
      return groupBy(this.ipoResults.filter(result => result.total_allocated_quantity > 0 && result.account_type === accountType), 'contact_name')
    },
    hasGroupByContactName (accountType) {
      return Object.keys(this.groupByContactName(accountType)).length > 0
    },
  }
}
</script>
