<template>
  <div>
    <div class='flex flex-row mt-8'>
      <h4 class='font-bold pl-3 pr-4 py-2'>{{title}}</h4>
      <button
        @click='copyToClipboard'
        class='pl-3 pr-4 flex flex-row justify-start gap-x-0.5 items-center py-2 rounded-md text-sm text-gray-700 border border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'>
        <clipboard-copy-icon class='h-4'/>
        복사
      </button>
    </div>
    <table class='text-sm table-fixed'>
      <thead>
        <tr>
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.fund_short_name'
            keyName='fund_short_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='펀드약칭'
            :optionObject='accounts'
            class='px-2 py-2 text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.fund_code'
            keyName='fund_code'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='펀드코드'
            :optionObject='accounts'
            class='px-2 py-2 text-left' />
          <th class='text-right'>배정수량</th>
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.fund_name'
            keyName='fund_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='펀드명'
            :optionObject='accounts'
            class='px-2 py-2 text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.entity_name'
            keyName='entity_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='수탁사명'
            :optionObject='accounts'
            class='px-2 py-2 text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.fund_account_name'
            keyName='fund_account_name'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='채권입고 계좌(수탁기관명)'
            :optionObject='accounts'
            class='px-2 py-2 text-left' />
          <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.fund_account_number'
            keyName='fund_account_number'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            defaultLabel='수탁기관코드'
            :optionObject='accounts'
            class='px-2 py-2 text-left' />
        </tr>
      </thead>
      <tbody v-for='account in filteredData' :key='account.id'>
        <tr>
          <td class='text-left'>{{account.fund_short_name}}</td>
          <td class='text-left'>{{account.fund_code}}</td>
          <td class='text-right'>{{numberStyle(allocatedQuantity(account))}}</td>
          <td class='text-left'>{{account.fund_name}}</td>
          <td class='text-left'>{{account.entity_name}}</td>
          <td>{{account.fund_account_name}}</td>
          <td>{{account.fund_account_number}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import TableHeaderFilterSelect from '@/components/TableHeaderFilterSelect.vue'
import { ClipboardCopyIcon }   from '@vue-hero-icons/outline'
import cloneDeep               from 'lodash/cloneDeep'

export default {
  name: 'IpoResultFundsAccountsRow',
  components: {
    TableHeaderFilterSelect,
    ClipboardCopyIcon,
  },
  props: [
    'accountType',
    'accounts',
  ],
  data () {
    return {
      sortDir: '',
      sortKey: '',
      filterTexts: {
        fund_short_name: [],
        fund_code: [],
        fund_name: [],
        entity_name: [],
        fund_account_name: [],
        fund_account_number: [],
      },
      filterKeys: [
        'fund_short_name',
        'fund_code',
        'fund_name',
        'entity_name',
        'fund_account_name',
        'fund_account_number',
      ],
      filterData: [],
    }
  },
  computed: {
    title () {
      return `<${this.accountType}>`
    },
    sortedData () {
      if (this.sortDir === '') {
        return this.accounts
      } else {
        this.sortData ( {key: this.sortKey, direction: this.sortDir} )
        return this.filterData
      }
    },
    filteredData () {
      return this.sortedData.filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    allocatedQuantity (account) {
      return account.new_allocated_quantity + account.old_allocated_quantity
    },
    sortData (sortInfo) {
      let sortAccounts = cloneDeep(this.accounts)
      sortAccounts.sort((aSort, bSort) => {
        let aValue, bValue
        if (typeof aSort === 'string') {
          aValue = aSort[sortInfo.key].toUpperCase()
          bValue = bSort[sortInfo.key].toUpperCase()
        } else {
          aValue = aSort[sortInfo.key]
          bValue = bSort[sortInfo.key]
        }

        if (sortInfo.direction === 'asc') {
          if (aValue < bValue) {
            return -1
          } else if (aValue > bValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (aValue < bValue) {
            return 1
          } else if (aValue > bValue) {
            return -1
          } else {
            return 0
          }
        }
      })
      this.filterData = sortAccounts
    },
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
    copyToClipboard () {
      let header = ['펀드약칭','펀드코드','배정수량','펀드명','수탁은행','채권입고 계좌(수탁기관명)','수탁기관코드','\n'].join('\t')
      let body = ''

      this.filteredData.forEach((account) => {
        body += [
          account.fund_short_name,
          account.fund_code,
          account.new_allocated_quantity + account.old_allocated_quantity,
          account.fund_name,
          account.entity_name,
          account.fund_account_name,
          account.fund_account_number,
          '\n',
        ].join('\t')
      })
      this.$copyText(header + body).then(() => {
        this.$message({
          type: 'success',
          message: '클립보드에 복사 됨.',
        })
      })
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
  td {
    @apply tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
</style>
