<template>
  <section class='overflow-x-scroll py-8'>
    <div class='flex flex-row justify-start gap-x-4 items-stretch'>
      <button class='flex flex-row justify-start gap-x-1 items-center py-2' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4' />
        시장데이터
      </button>
      <button
        @click='copyToClipboard'
        class='pl-3 pr-4 flex flex-row justify-start gap-x-0.5 items-center py-2 rounded-md text-sm text-gray-700 border border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'>
        <clipboard-copy-icon class='h-4'/>
        복사
      </button>
      <a class='pl-3 pr-4 flex flex-row justify-start gap-x-0.5 items-center py-2 rounded-md text-sm text-gray-700 border border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md' href='https://www.smes.go.kr/venturein/pbntc/searchVntrCmp' target='_blank'>
        <external-link-icon class='h-4'/>
        벤처확인기업 공시
      </a>
    </div>
    <table v-if='showDetails'
      class='ipo-table'>
      <thead>
        <tr>
          <th>ID</th>
          <th>종목명</th>
          <th>KR코드</th>
          <th>상장일</th>
          <th>만기일</th>
          <th>기초자산</th>
          <th>가격코드</th>
          <th>비평가</th>
          <th>거래가능</th>
          <th>거래단위</th>
          <th>주식구분</th>
          <th>사용자구분</th>
          <th>벤처신주</th>
          <th>권리구분</th>
          <th>TICS</th>
          <th>GICS</th>
          <th>WICS</th>
          <th>거래소</th>
          <th>통화</th>
          <th>호가단위</th>
          <th>블룸버그</th>
          <th>미래</th>
          <th>BPV</th>
          <th>결산월</th>
          <th>벤처지정일</th>
          <th>벤처해제일</th>
        </tr>
      </thead>
      <tbody>
        <tr class='border-t'>
          <td>A{{selectedIpo.share_code}}</td>
          <td>{{selectedIpo.name}}</td>
          <td>
            <input class='form-input'
              type='text'
              v-model='selectedIpo.isin_code'>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>TRUE</td>
          <td></td>
          <td></td>
          <td>
            <select v-model='selectedStockType'
              class='form-select w-50'>
              <option v-for='type in selectableStockTpyes'
                :key='type'
                :value='type'>
                {{type}}
              </option>
            </select>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Unlisted</td>
          <td>KRW</td>
          <td></td>
          <td></td>
          <td></td>
          <td>1</td>
          <td>12</td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 10rem'
              @change='updateUserDetail("벤처지정일")'
              v-model='ventureStartDate'>
          </td>
          <td>
            <input class='form-input'
              type='text'
              style='width: 10rem'
              @change='updateUserDetail("벤처해제일")'
              v-model='ventureEndDate'>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon, ClipboardCopyIcon, ExternalLinkIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoMarketData',
  components: {
    ChevronDownIcon,
    ChevronRightIcon,
    ClipboardCopyIcon,
    ExternalLinkIcon,
  },
  data () {
    return {
      selectableStockTpyes: [
        'Comp',
        'SPAC',
      ],
      selectedStockType: '',
      showDetails: false,
      notEditing: false,
      ventureStartDate: '',
      ventureEndDate: '',
    }
  },
  watch: {
    'stockType': {
      handler: function (newVal) {
        this.selectedStockType = newVal
      },
      immediate: true
    },
    'selectedIpo.details': {
      handler: function (newVal) {
        if (newVal) {
          this.ventureStartDate = this.selectedIpo.details && this.selectedIpo.details[this.currentIpoDetailsName] && this.selectedIpo.details[this.currentIpoDetailsName]['벤처지정일'] ? this.selectedIpo.details[this.currentIpoDetailsName]['벤처지정일'] : ''
          this.ventureEndDate = this.selectedIpo.details && this.selectedIpo.details[this.currentIpoDetailsName] && this.selectedIpo.details[this.currentIpoDetailsName]['벤처해제일'] ? this.selectedIpo.details[this.currentIpoDetailsName]['벤처해제일'] : ''
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    ...mapGetters('users', [
      'currentIpoDetailsName',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    hasSelectedIpo () {
      return Object.keys(this.selectedIpo).length > 0
    },
    stockType () {
      return this.hasSelectedIpo ?
              (this.selectedIpo.name.includes('스팩') || this.selectedIpo.name.includes('기업인수')) ? 'SPAC' : 'Comp' :
              ''
    },
  },
  methods: {
    ...mapActions ('ipos', [
      'updateIpoDetail',
      'patchIpoDetails',
    ]),
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    copyToClipboard () {
      let result = [
        'A'+this.selectedIpo.share_code, // ID
        this.selectedIpo.name, // 종목명
        this.selectedIpo.isin_code, // 종목코드
        '', // 상장일
        '', // 만기일
        '', // 기초자산
        '', // 가격코드
        'TRUE', // 비평가
        '', // 거래가능
        '', // 거래단위
        this.selectedStockType, // 주식구분
        '', // 사용자구분
        '', // 벤처신주
        '', // 권리구분
        '', // TICS
        '', // GICS
        '', // WICS
        'Unlisted', // 거래소
        'KRW', // 통화
        '', // 호가단위
        '', // 블룸버그
        '', // 미래
        1, // BPV
        12, // 결산월
        this.ventureStartDate, // 벤처지정일
        this.ventureEndDate // 벤처해제일
      ].join('\t')
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: '클립보드에 복사 됨.',
        })
      })
    },
    updateUserDetail (key) {
      if (event) {
        this.updateIpoDetail( {id: this.selectedIpo.id, currentIpoDetailsName: this.currentIpoDetailsName, key: key, type: 'text', value: event.target.value} ).then(() => {
          this.patchIpoDetails(this.selectedIpo)
        })
      }
    },
  }
}
</script>
