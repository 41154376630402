<template>
  <div class='px-2 lg:px-8 py-4'>
    <div class='mt-4 lg:mt-12'>
      <div class='flex flex-row justify-start items-center gap-x-2'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide mr-4'>회계상구분</h1>
        <button
          class='px-2 py-2 lg:py-3 border rounded-md border-gray-200 flex flex-row justify-center items-center text-xs text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='addNewAccountingType'>
          <plus-circle-icon class='h-4' />
          Add
        </button>
      </div>

      <table class='mt-4 text-sm w-full lg:w-auto'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b border-t'>
            <th class='px-2 py-2 bg-gray-100 text-center w-1/2 lg:w-64 border-r border-l'>회계상구분</th>
            <th class='px-2 py-2 bg-gray-100 text-center w-1/2 lg:w-64 border-r'>참고사항</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(accountingType, index) in accountingTypes'
            :key='`accountingType-${accountingType.id}-${index}`'
            class='border-b'>
            <td class='text-left border-r border-l'>
              <input type='text' v-model='accountingType.codeName' class='form-input w-full text-left'
                @paste.prevent='onPasteCodes($event, index)' />
            </td>
            <td class='text-left border-r'>
              <input type='text' v-model='accountingType.codeNote' class='form-input w-full text-left' />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if='!accountingTypes.length'
        class='w-full max-w-lg bg-gray-100 py-16 text-center'>
        No codes yet.
      </div>
      <button v-else
        class='mt-4 px-2 lg:px-4 py-2 lg:py-3 font-semibold text-right border rounded-md border-gray-200 hover:border-blue-300 opacity-90 hover:opacity-100 hover:shadow-md text-xs uppercase bg-gray-900 text-white'
        @click='uploadLedgerCode("accounting_type")'>
        Save Changes
      </button>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CopyPasteHelpers    from '@/utils/copy-paste-helpers'
import { PlusCircleIcon }  from '@vue-hero-icons/outline'
import { mapMultiRowFields } from 'vuex-map-fields'

export default {
  name: 'LedgerPiCodeEdit',
  components: {
    PlusCircleIcon,
  },
  computed: {
    ...mapGetters('ledgerCodes', {
      stateAccountingTypes: 'accountingTypes',
    }),
    ...mapMultiRowFields('ledgerCodes', [
      'ledgerCodes',
    ]),
    accountingTypes () {
      return this.ledgerCodes.filter(ledgerCode => ledgerCode.codeType === 'accounting_type')
    },
  },
  methods: {
    ...mapActions('ledgerCodes', [
      'getLedgerCodes',
      'postLedgerCodes',
    ]),
    ...mapMutations('ledgerCodes', [
      'addLedgerCodes',
    ]),
    onPasteCodes (data, targetIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      pastedArray.forEach((row, index) => {
        this.updateTargetRow(this.accountingTypes[targetIndex + index], row)
      })
    },
    updateTargetRow (targetRow, pastedRow) {
      var index = 0

      targetRow.codeName = pastedRow[index++] || ''
      targetRow.codeNote = pastedRow[index++] || ''
    },
    addNewAccountingType () {
      this.addLedgerCodes('accounting_type')
    },
    uploadLedgerCode (codeType) {
      this.postLedgerCodes(codeType)
    },
  },
  mounted () {
    this.getLedgerCodes('all')
  },
}
</script>
