<template>
  <tbody>
    <ipo-broker-account-row
      :isRoot='true'
      :key='brokerAccounts[0].broker_name'
      :brokerAccount='brokerAccounts[0]'
      v-bind:toggle-details.sync='showDetails'
      :isVisible='true' />
    <ipo-broker-account-row
      :isRoot='false'
      v-for='brokerAccount in brokerAccounts'
      :key='`${brokerAccount.broker_name}-${brokerAccount.account_type}`'
      :brokerAccount='brokerAccount'
      :isVisible='showDetails' />
  </tbody>
</template>

<script>
import IpoBrokerAccountRow from '@/views/ipo/IpoBrokerAccountRow.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'IpoBrokerAccountRowGroup',
  components: {
    IpoBrokerAccountRow,
  },
  props: [
    'brokerAccounts'
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  watch: {
    showDetails: {
      handler: function () {
        if (this.showDetails) {
          this.showBrokerAccountDetails()
        }
      }
    }
  },
  computed: {
    ...mapState('brokers', [
      'brokers',
    ]),
    ...mapState('brokerAccounts', [
      'emptyBrokerAccounts',
    ]),
    aggregateAccount () {
      return this.brokerAccounts.find(act => act.account_type === '집합')
    },
    ventureAccount () {
      return this.brokerAccounts.find(act => act.account_type === '벤처')
    },
    highYieldAccount () {
      return this.brokerAccounts.find(act => act.account_type === '하이일드')
    },
    selfAccount () {
      return this.brokerAccounts.find(act => act.account_type === '고유')
    },
  },
  methods: {
    ...mapActions('brokerAccounts', [
      'brokerAccountDetailsOpen'
    ]),
    ...mapMutations('brokerAccounts', [
      'setDrilldownBroker'
    ]),
    showBrokerAccountDetails () {
      let accounts = []
      if (this.aggregateAccount) {
        accounts.push(this.aggregateAccount)
      } else {
        accounts.push(this.emptyBrokerAccounts[0])
      }
      if (this.ventureAccount) {
        accounts.push(this.ventureAccount)
      } else {
        accounts.push(this.emptyBrokerAccounts[1])
      }
      if (this.highYieldAccount) {
        accounts.push(this.highYieldAccount)
      } else {
        accounts.push(this.emptyBrokerAccounts[1])
      }
      if (this.selfAccount) {
        accounts.push(this.selfAccount)
      } else {
        accounts.push(this.emptyBrokerAccounts[2])
      }
      this.setDrilldownBroker(this.brokers.find(broker => broker.name === this.brokerAccounts[0].broker_name))
      this.brokerAccountDetailsOpen(accounts)
    },
  },
}
</script>
