<template>
  <div>
    <h1 class='text-2xl font-semibold px-8 py-8'>종목정보추가</h1>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>종목정보가져오기</label>
      <select v-model='selectedInvestment' class='form-select' @change='updateListingFromInvestment'>
         <option v-for='investment in investments'
           :key='`${investment.id}-${investment.investment_detail_id}`'
           :value='investment'>
           {{ investment.investment_name }}-{{investment.asset_class}}
         </option>
      </select>
    </div>

    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>종목코드</label>
      <input class='form-input w-1/4' v-model='listing.share_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>종목명</label>
      <input class='form-input w-1/4' v-model='listing.name'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>펀드구분</label>
      <select v-model='listing.fund_type' class='form-select'>
        <option value='사모투자신탁'>사모투자신탁</option>
        <option value='사모투자조합'>사모투자조합</option>
        <option value='사모투자합자회사'>사모투자합자회사</option>
        <option value='신기술사업투자조합'>신기술사업투자조합</option>
        <option value='중소기업차업투자조합'>중소기업차업투자조합</option>
        <option value='한국벤처투자조합'>한국벤처투자조합</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>펀드형태</label>
      <select v-model='listing.fund_form' class='form-select'>
        <option value='블라인드'>블라인드</option>
        <option value='프로젝트'>프로젝트</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>기업코드</label>
      <input class='form-input w-1/4' v-model='listing.company_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>기초자산코드</label>
      <input class='form-input w-1/4' v-model='listing.base_asset_code'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>펀드결성일</label>
      <el-date-picker
        v-model='listing.fund_start_date'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='펀드결성일'>
      </el-date-picker>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>펀드약정총액</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.fund_contract_value'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>1좌금액</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.fund_share_value'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>만기연장조건</label>
      <input class='form-input w-1/4 text-left' type='text' v-model='listing.fund_delay_condition'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>기준수익률(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.base_earning_rate'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>성과보수율(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.carried_interest_rate'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>관리보수율(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.management_interest_rate'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>관리보수기준</label>
      <input class='form-input w-1/4 text-left' type='text' v-model='listing.management_interest_standard'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>* 구주/신주</label>
      <select v-model='listing.old_or_new' class='form-select'>
        <option value='구주'>구주</option>
        <option value='신주'>신주</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/4'>투자단가</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='listing.investment_price'>
    </div>

    <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
      <button
        class='border px-6 h-12 text-sm text-white rounded-md font-bold'
        style='background-color: #223645;'
        @click='submitListing'>저장
      </button>
      <span class='font-semibold'>{{ result }}</span>
      <div v-if='canDelete'>
        <button
          name='show-delete-btn'
          class='p-3 text-red-700
          text-sm border border-transparent hover:border-red-400 rounded-md'
          @click='deleteListing'>Delete <span class='font-semibold'>{{listing.name}}</span>
      </button>
      </div>
    </div>

  </div>
</template>

<script>
import ListingsApi                           from '@/api/v1/listings'
import cloneDeep                             from 'lodash/cloneDeep'
import { mapState, mapGetters }              from 'vuex'
import InvestmentsApi                        from '@/api/v1/investments'

export default {
  name: 'FundListingEdit',
  components: {
  },
  data () {
    return {
      showDelete: true,
      listing: {asset_class: '', is_direct: false},
      investments: [],
      selectedInvestment: {},
      result: 'No result',
    }
  },
  computed: {
    ...mapState('listings', [
      'drilldownListing',
    ]),
    ...mapGetters('entities', [
      'drilldownEntityId',
    ]),
    canDelete () {
      return this.listing.id > 0
    },
    actionButtonsLocation () {
      return 'modal-footer'
    },
    titleLocation () {
      return 'modal-header'
    }
  },
  methods: {
    submitListing () {
      if (!this.listing.share_code || this.listing.share_code.length < 6) {
        alert('종목코드는 최소 6자리입니다 (ex: A005930)')
        return
      }

      if (this.listing.share_code[0] !== 'F') {
        alert('간접투자자산이 아닙니다.')
        return
      }

      if (this.listing.id > 0) {
        ListingsApi.patchListing(this.listing.id, this.listing).then(resp => {
          this.result = resp
          this.doneEditing()
        })
      } else {
        ListingsApi.postListing(this.listing).then(resp => {
          this.result = resp
          this.doneEditing()
        })
      }
    },
    updateListingFromInvestment () {
      this.listing.asset_class = this.selectedInvestment.asset_class
      this.listing.old_or_new = this.selectedInvestment.is_new_shares
    },
    deleteListing () {
      var deleteConfirm = confirm(this.listing.name + ' 종목정보가 삭제됩니다')
      if (!deleteConfirm) {
        alert('종목정보 삭제가 취소되었습니다.')
        return
      }
      ListingsApi.deleteListing(this.listing.id).then((resp) => {
        this.result = resp
        this.doneEditing()
      })
      this.showDelete = false
    },
    doneEditing () {
      this.$emit('done-editing')
    },
    getInvestmentEntity () {
      InvestmentsApi.getInvestmentForLedger({ entity_id: this.drilldownEntityId, is_direct: false, search_text: '' }).then(resp => {
        this.investments = resp
      })
    },
  },
  mounted () {
    this.listing = cloneDeep(this.drilldownListing)
    this.getInvestmentEntity ()
  },
}
</script>
