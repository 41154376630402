<template>
  <div class='flex flex-row justify-start items-stretch gap-x-1 py-2'>
    <ipo-search class='flex-grow lg:flex-grow-0' />
    <button v-if='hasSelectedIpo' 
      @click='refreshIpoSummaries' 
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <refresh-icon class='inline-block h-5' style='margin-top: -5px;' /> 
      조회
    </button>
    <button v-if='hasSelectedIpo'
      @click='updateIpoDetails'
      class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md whitespace-no-wrap'>
      <save-icon class='inline-block h-5' style='margin-top: -5px;' />
      저장
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { RefreshIcon, SaveIcon } from '@vue-hero-icons/outline'
import IpoSearch from '@/components/IpoSearch.vue'

export default {
  name: 'IpoAllocatedLists',
  components: {
    RefreshIcon,
    SaveIcon,
    IpoSearch,
  },
  computed: {
    ...mapFields('ipos', [
      'selectedIpo',
    ]),
    ...mapState('ipos', [
      'ipos',
      'ipoSummaries',
    ]),
    hasSelectedIpo () {
      return (this.selectedIpo.id > 0)
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
      'getIpoSummaries',
      'updateSelectedIpo',
      'patchIpoDetails',
    ]),
    refreshIpoSummaries () {
      this.getIpoSummaries()
      this.getIpos().then(() => {
        this.updateSelectedIpo()
      })
    },
    updateIpoDetails () {
      this.updateSelectedIpo()
      this.patchIpoDetails(this.selectedIpo).then(() => {
        this.refreshIpoSummaries()
      })
    },
  },
}
</script>
