<template>
  <div>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>IPO</h1>
      </div>
    </portal>
    <div class='flex flex-row justify-start lg:pl-8 pt-4 border-b overflow-x-auto border-gray-100 text-gray-600' style='width:100vw;max-width:100%;'>
      <label
       :class='selectedStyle("ipo-demand-forecast")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t block cursor-pointer whitespace-no-wrap hover:bg-gray-100'>
        <input value='ipo-demand-forecast' v-model='view' type='radio' name='view' class='hidden'/>
        수요예측
      </label>
      <label
       :class='selectedStyle("ipo-allocated")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t block cursor-pointer whitespace-no-wrap hover:bg-gray-100'>
        <input value='ipo-allocated' v-model='view' type='radio' name='view' class='hidden'/>
        배정보고
      </label>
      <label
       :class='selectedStyle("ipo-public-offering")'
       class='mr-8 px-4 pb-2 pt-3 rounded-t block cursor-pointer whitespace-no-wrap hover:bg-gray-100'>
        <input value='ipo-public-offering' v-model='view' type='radio' name='view' class='hidden'/>
        청약
      </label>
      <label
        :class='selectedStyle("ipo-broker-accounts")'
        class='mx-8 px-4 pb-2 pt-3 rounded-t block cursor-pointer whitespace-no-wrap hover:bg-gray-100'>
        <input value='ipo-broker-accounts' v-model='view' type='radio' name='view' class='hidden'/>
        증권사정보
      </label>
      <label
        :class='selectedStyle("ipo-information")'
        class='mx-8 px-4 pb-2 pt-3 rounded-t block cursor-pointer whitespace-no-wrap hover:bg-gray-100'>
        <input value='ipo-information' v-model='view' type='radio' name='view' class='hidden'/>
        수요예측정보
      </label>
      <label
        :class='selectedStyle("ipo-portfolio")'
        class='mx-8 px-4 pb-2 pt-3 rounded-t block cursor-pointer whitespace-no-wrap hover:bg-gray-100'>
        <input value='ipo-portfolio' v-model='view' type='radio' name='view' class='hidden'/>
        IPO Portfolio
      </label>
    </div>
    <component :is='view' class='lg:pb-8 px-2 lg:pl-8' />
  </div>
</template>

<script>
import IpoDemandForecast from '@/views/ipo/IpoDemandForecast.vue'
import IpoAllocated      from '@/views/ipo/IpoAllocated.vue'
import IpoPublicOffering from '@/views/ipo/IpoPublicOffering.vue'
import IpoBrokerAccounts from '@/views/ipo/IpoBrokerAccounts.vue'
import IpoInformation    from '@/views/ipo/IpoInformation.vue'
import IpoPortfolio      from '@/views/ipo/IpoPortfolio.vue'

export default {
  name: 'Ipo',
  components: {
    IpoDemandForecast,
    IpoAllocated,
    IpoPublicOffering,
    IpoBrokerAccounts,
    IpoInformation,
    IpoPortfolio,
  },
  data () {
    return {
      view: 'ipo-demand-forecast',
    }
  },
  methods: {
    selectedStyle (tab) {
      return (tab === this.view) ? 'text-gray-800 font-semibold border-b-2 border-gray-800' : 'border-b-2 border-transparent'
    },
  },
}
</script>

<style lang='scss'>
.ipo-table {
  @apply table-fixed text-sm text-gray-700;
}

.ipo-table thead th {
  @apply py-2 pl-1 pr-2 text-left font-semibold sticky top-0 whitespace-no-wrap;
}

.ipo-table tbody td {
  @apply py-1 pl-1 pr-2 border-b whitespace-no-wrap;
}

.ipo-table thead th.text-right,
.ipo-table thead td.text-right {
  @apply text-right;
}

.ipo-table thead th.text-center,
.ipo-table thead td.text-center {
  @apply text-center;
}

@media (min-width: 1024px) {
  .ipo-table thead th {
    @apply py-3 pl-3 pr-2;
  }

  .ipo-table tbody td {
    @apply py-3 pl-3 pr-2;
  }

  .ipo-table tbody tr.short-cell td {
    @apply py-0;
  }
}
</style>
