<template>
<table class='bg-white mx-auto' style='width: 210mm'>
  <thead>
    <tr>
      <td>
        <header class='flex flex-row justify-between items-center border-b-2 pb-2'>
          <h3 class='text-lg'>투자심사위원회</h3>
          <img src='logo_timefolio.png' class='h-12 block' />
        </header>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div>
          <h1 class='w-full text-center mt-4 mb-8 text-5xl'>투자심의위원회</h1>
          <table class='border-collapse w-full'>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투자대상
              </td>
              <td class='border border-black p-2 align-top'>
                {{ drilldownInvestment.entity_name }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                리스크평가등급
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReviewBoardReport.reports.risk_rating }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심일시
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReviewBoardReport.reports.investment_review_date }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                담당자
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReviewBoardReport.reports.user_name }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                참석자
              </td>
              <td class='border border-black p-2 align-top'>
                황성환, 안승우, 박중환, 이원석
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심결과
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReviewBoardReport.reports.result }}
              </td>
            </tr>
          </table>

          <section class='mt-8'  style='page-break-after: always;'>
            <label>
              <input type='checkbox' />
              투자조건
            </label>
            <div class='border-2 border-black'>
              <table class='w-full'>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자기업명</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ drilldownInvestment.entity_name }}</td>
                </tr>
                <tr v-if='investmentReviewBoardReport.reports.investment_type' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자형태</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.investment_type }}</td>
                </tr>
                <tr v-if='investmentReviewBoardReport.reports.source' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자재원</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.source }}</td>
                </tr>
                <tr v-if='investmentReviewBoardReport.reports.investment_date' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자예정일</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.investment_date }}</td>
                </tr>
                <tr v-if='investmentReviewBoardReport.reports.investment_amount' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>당사 인수금액</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.investment_amount }} 원</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>인수단가</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>
                    {{ investmentReviewBoardReport.reports.investment_price }}<br>
                    <div v-html='conversionUnitPrice' />
                  </td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>인수주식수</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>
                    {{ investmentReviewBoardReport.reports.investment_quantity }}<br>
                    <div v-html='sharesConversion' />
                  </td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>투자밸류</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.investment_value }}</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>전환시지분율</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.investment_ratio }} %</td>
                </tr>
                <tr v-if='investmentReviewBoardReport.reports.memo' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top'>기타</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReviewBoardReport.reports.memo }}</td>
                </tr>
              </table>
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              주요쟁점
            </label>
            <div class='p-4 border border-black whitespace-pre-line'>
              {{ investmentReviewBoardReport.reports.major_issue }}
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              투심위원의견
            </label>
            <div class='whitespace-pre'>
              <table class='w-full'>
                <tr class='bg-gray-300' style='page-break-inside: avoid;'>
                  <td v-for='(header, index) in investmentReviewBoardReport.reports.opinions_table_headers' :key='index'
                      class='border border-black p-2 align-top text-center'>
                    {{ header['name'] }}
                  </td>
                </tr>
                <tr v-for='(opinion, index) in investmentReviewBoardReport.reports.opinions' :key='index'>
                  <td v-for='(header, index) in investmentReviewBoardReport.reports.opinions_table_headers'
                      :key='index'
                      :class="selectedStyle(opinion[header['column_type']])">
                    {{ opinion[header['name']] }}
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              투자조건의 변경 등에 관한 사항
            </label>
            <div class='p-4 border border-black whitespace-pre-line'>
              {{ investmentReviewBoardReport.reports.conditions }}
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' />
              기타의견
            </label>
            <div class='p-4 border border-black whitespace-pre-line'>
              {{ investmentReviewBoardReport.reports.other_opinions }}
            </div>
          </section>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
</table>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  name: 'InvestmentReviewReport',
  data () {
    return {
      investment_id: this.$route.query.investment_id,
    }
  },
  computed: {
    ...mapState('investments', [
      'investmentReviewBoardReport',
      'drilldownInvestment',
    ]),
    conversionUnitPrice () {
      if (this.investmentReviewBoardReport.reports.conversion_unit_price) {
        if (this.investmentReviewBoardReport.reports.conversion_unit_price.split('\n') > 0) {
          return this.investmentReviewBoardReport.reports.conversion_unit_price.split('\n').join('<br />')
        }
        else {
          return this.investmentReviewBoardReport.reports.conversion_unit_price
        }
      }
      else {
        return ''
      }
    },
    sharesConversion () {
      if (this.investmentReviewBoardReport.reports.shares_conversion) {
        if (this.investmentReviewBoardReport.reports.shares_conversion.indexOf('\n') > 0) {
          return this.investmentReviewBoardReport.reports.shares_conversion.indexOf('\n').join('<br />')
        }
        else {
          return this.investmentReviewBoardReport.reports.shares_conversion
        }
      }
      else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentData',
      'getInvestmentReviewBoardReports',
    ]),
    selectedStyle(column_type) {
      if (column_type === 'text') {
        return 'border border-black whitespace-pre-line text-left'
      }
      else {
        return 'border border-black whitespace-pre-line text-center'
      }
    },
  },
  mounted () {
    this.getInvestmentData(this.investment_id).then(() => {
      this.getInvestmentReviewBoardReports(this.investment_id)
    })
  }
}
</script>

<style lang='scss' scoped>

@media print {
  @page {
    margin: 1cm;
  }
}
</style>
