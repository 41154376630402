<template>
  <section v-if='hasIpoResults' class='overflow-x-scroll py-8'>
    <h4>
      <span class='py-2 cursor-pointer' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4 inline-block inline-block' />
        펀드+계좌수탁사별 배정수량
      </span>
    </h4>
    <div v-if='showDetails' class='py-2 text-sm'>
      <template v-for='accountType in accountTypes'>
        <ipo-result-funds-accounts-row
          v-if='hasFilteredAccount(accountType)'
          :key='accountType'
          :account-type='accountType'
          :accounts='filteredAccount(accountType)' />
      </template>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'
import IpoResultFundsAccountsRow from '@/views/ipo/IpoResultFundsAccountsRow.vue'

export default {
  name: 'IpoResultFundsAccounts',
  components: {
    IpoResultFundsAccountsRow,
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'accountTypes',
      'ipoResults',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    filteredResults () {
      return this.ipoResults.filter(result => result.total_allocated_quantity > 0)
    },
    hasIpoResults () {
      return this.filteredResults.length > 0
    },
  },
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    filteredAccount (accountType) {
      return this.ipoResults.filter(result => result.total_allocated_quantity > 0 && result.account_type === accountType)
    },
    hasFilteredAccount (accountType) {
      return this.filteredAccount(accountType).length > 0
    },
  }
}
</script>
