<template>
  <div>
    <ipo-lists />
    <ipo-details v-if='hasSelectedIpo' />
    <ipo-summaries v-if='hasSelectedIpo' />
    <ipo-account-type-reports v-if='hasSelectedIpo' account-type='집합' />
    <ipo-account-type-reports v-if='hasSelectedIpo' account-type='벤처' />
    <ipo-account-type-reports v-if='isHighYield' account-type='하이일드' />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import IpoLists                from '@/views/ipo/IpoLists.vue'
import IpoDetails              from '@/views/ipo/IpoDetails.vue'
import IpoSummaries            from '@/views/ipo/IpoSummaries.vue'
import IpoAccountTypeReports   from '@/views/ipo/IpoAccountTypeReports.vue'

export default {
  name: 'IpoDemandForecast',
  components: {
    IpoLists,
    IpoDetails,
    IpoSummaries,
    IpoAccountTypeReports,
  },
  computed: {
    ...mapState('ipos', [
      'ipoResults',
    ]),
    ...mapGetters('ipos', [
      'hasSelectedIpo',
    ]),
    isHighYield () {
      return this.ipoResults.filter(ipo => ipo.account_type === '하이일드').length > 0
    },
  },
  methods: {
    ...mapActions('ipos', [
      'getIpos',
    ]),
    ...mapActions('funds', [
      'getFunds',
    ]),
    ...mapActions('brokers', [
      'getBrokers',
    ]),
    refreshData () {
      this.getIpos()
      this.getFunds()
      this.getBrokers()
    },
  },
  mounted () {
    this.refreshData()
  }
}
</script>
