<template>
  <div>
    <div class='flex flex-row my-6'>
      <h1 class='mr-2 text-3xl font-semibold tracking-wide'>Brokers</h1>
      <button
        class='border p-3 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='addNewBrokerAccount()'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;'></plus-circle-icon>
      </button>
    </div>
    <table class='ipo-table' style='min-width: 24rem;'>
      <thead>
        <tr>
          <th>증권사명</th>
          <th>계좌번호</th>
          <th>이메일</th>
          <th>연락처</th>
          <th>Note</th>
        </tr>
      </thead>
      <ipo-broker-account-row-group
        v-for='(brokerAccounts, brokerName) in groupBrokerAccounts'
        :key='`group-${brokerName}`'
        :broker-accounts='brokerAccounts' />
    </table>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import groupBy   from 'lodash/groupBy'
import cloneDeep from 'lodash/cloneDeep'
import IpoBrokerAccountRowGroup from '@/views/ipo/IpoBrokerAccountRowGroup.vue'

export default {
  name: 'IpoBrokerAccounts',
  components: {
    IpoBrokerAccountRowGroup,
    PlusCircleIcon,
  },
  computed: {
    ...mapState('brokers', [
      'brokers',
    ]),
    ...mapState('brokerAccounts', [
      'brokerAccounts',
      'emptyBrokerAccounts',
    ]),
    groupBrokerAccounts () {
      return groupBy(this.brokerAccounts, 'broker_name')
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('brokerAccounts', [
      'getBrokerAccounts',
      'brokerAccountDetailsOpen',
    ]),
    ...mapMutations('brokerAccounts', [
      'setDrilldownBroker',
    ]),
    addNewBrokerAccount () {
      this.setDrilldownBroker({})
      this.brokerAccountDetailsOpen(cloneDeep(this.emptyBrokerAccounts))
    }
  },
  mounted () {
    this.getBrokerAccounts()
  },
  beforeDestroy () {
    this.sidepanelClose()
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply py-3 pl-2 pr-6 border-b text-left uppercase tracking-wide sticky top-0 whitespace-no-wrap;
  }
</style>
