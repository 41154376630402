<template>
<table class='bg-white mx-auto' style='width: 210mm'>
  <thead>
    <tr>
      <td>
        <header class='flex flex-row justify-between items-center border-b-2 pb-2 mb-8'>
          <h3 class='text-lg'>투자심사위원회</h3>
          <img src='logo_timefolio.png' class='h-12 block' />
        </header>
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        <div>
          <h1 class='w-full text-center mb-8 text-5xl'>투자심의위원회 의사록</h1>
          <table class='border-collapse w-full' style='page-break-after: always;'>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심업체명
              </td>
              <td class='border border-black p-2 align-top'>
                {{ drilldownInvestment.entity_name }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심일시
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReportMinutes.reports.investment_review_date }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심장소
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReportMinutes.reports.place }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                담당자
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReportMinutes.reports.user_name }}
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                참석자
              </td>
              <td class='border border-black p-2 align-top'>
                황성환, 안승우, 박중환, 이원석
              </td>
            </tr>
            <tr>
              <td class='border border-black p-2 align-top text-center'>
                투심결과
              </td>
              <td class='border border-black p-2 align-top'>
                {{ investmentReportMinutes.reports.result }}
              </td>
            </tr>
          </table>

          <section class='mt-8' style='page-break-after: always;'>
            <label>
              <input type='checkbox' disabled='true' />
              투자조건
            </label>
            <div class='border-2 border-black'>
              <table class='w-full'>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자기업명</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ drilldownInvestment.entity_name }}</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자형태</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.investment_type }}</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자예정일</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.investment_date }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.total_issue_amount' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>총 발행금액</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.total_issue_amount }}</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>당사 투자금액</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.investment_amount }}</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자단가</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>
                    {{ investmentReportMinutes.reports.investment_price }}<br>
                    <div v-html='conversionUnitPrice' />
                  </td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자주식수</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>
                    {{ investmentReportMinutes.reports.investment_quantity }}<br>
                    <div v-html='sharesConversion' />
                  </td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자지분율</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.investment_ratio }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.convertible_period' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>전환가능기간</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.convertible_period }}</td>
                </tr>
                <tr style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자밸류</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.investment_value }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.conversion_percentage' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>전환시지분율</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.conversion_percentage }}</td>
                </tr>
                <tr v-if='cpsDetail' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>발행일</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>{{ cpsIssueDate }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.put_maturity' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>PUT/만기(CB)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>{{ investmentReportMinutes.reports.put_maturity }}</td>
                </tr>
                <tr v-if='bondDetail' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>Coupon/YTM(CB)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>{{ couponAndYtm }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.duration' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>존속기간(CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>{{ investmentReportMinutes.reports.duration }}</td>
                </tr>
                <tr v-if='cpsDetail' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>우선배당(CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top'>{{ preferredDividend }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.refixing' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>Refixing(CB, CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.refixing }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.call_option' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>Call 옵션(CB, CPS)</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.call_option }}</td>
                </tr>
                <tr v-if='investmentReportMinutes.reports.purpose_investment' style='page-break-inside: avoid;'>
                  <td class='p-2 align-top whitespace-no-wrap'>투자금 용도</td>
                  <td class='p-2 align-top w-6'>:</td>
                  <td class='p-2 align-top' colspan='4'>{{ investmentReportMinutes.reports.purpose_investment }}</td>
                </tr>
              </table>
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' disabled='true' />
              기타의견
            </label>
            <div class='p-4 border border-black'>
              {{ investmentReportMinutes.reports.other_opinions }}
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' disabled='true' />
              투자조건의 변경(or 사유)
            </label>
            <div class='p-4 border border-black'>
              {{ investmentReportMinutes.reports.changes_term }}
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' disabled='true' />
              주요쟁점
            </label>
            <div class='p-4 border border-black whitespace-pre-line'>
              {{ investmentReportMinutes.reports.major_issue }}
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' disabled='true' />
              자산배분
            </label>
            <div class='p-4 border border-black whitespace-pre-line'>
              {{ investmentReportMinutes.reports.allocation }}
            </div>
          </section>

          <section class='mt-8' style='page-break-inside: avoid;'>
            <label>
              <input type='checkbox' disabled='true' />
              핵심운용인력의 해당 투자자산 편입 동의 여부
            </label>
            <table v-if='isQuestionAssent' class='border-collapse w-full'>
              <tr class='bg-gray-300'>
                <td v-for='(header, index) in investmentReportMinutes.reports.question_assent_table_headers' :key='index'
                    class='border border-black p-2 align-top text-center'>
                  {{ header['name'] }}</td>
              </tr>
              <tr v-for='(value, key) in investmentReportMinutes.reports.question_assent' :key='key'>
                <td v-for='(header, index) in investmentReportMinutes.reports.question_assent_table_headers' :key='index'
                    class='border border-black p-2'>
                  {{ value[header['name']] }}
                </td>
              </tr>
            </table>
          </section>

          <h1 class='w-full text-center mt-4 mb-8 text-5xl' style='page-break-before: always;'>투심위의견서</h1>
          <label class='block'>
            <input type='checkbox' disabled='true' />
            {{ investmentReviewDate }}
          </label>
          <label class='block'>
            <input type='checkbox' disabled='true' />
            {{ investmentEntityName }}
          </label>

          <section class='mt-8' style='page-break-inside: avoid; page-break-after: always;'>
            <div class='whitespace-pre'>
              <table class='w-full'>
                <tr class='bg-gray-300' style='page-break-inside: avoid;'>
                  <td v-for='(header, index) in investmentReportMinutes.reports.opinions_table_headers' :key='index'
                      class='border border-black p-2 align-top text-center'>
                    {{ header['name'] }}
                  </td>
                </tr>
                <tr v-for='(opinion, index) in investmentReportMinutes.reports.opinions' :key='index'>
                  <td v-for='(header, index) in investmentReportMinutes.reports.opinions_table_headers'
                      :key='index'
                      :class="selectedStyle(opinion[header['column_type']])">
                      <!-- class='border border-black whitespace-pre-line'> -->
                    {{ opinion[header['name']] }}
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <reports-sign-page
            :report-title='reportTitle'
            :report-date='investmentReportMinutes.reports.investment_review_date' />
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot>
</table>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
import ReportsSignPage from '@/views/reports/ReportsSignPage.vue'

export default {
  name: 'InvestmentReportMinutes',
  components: {
    ReportsSignPage,
  },
  data () {
    return {
      investment_id: this.$route.query.investment_id,
      approval_request_id: this.$route.query.approval_request_id,
    }
  },
  computed: {
    ...mapState('investments', [
      'investmentReportMinutes',
      'drilldownInvestment',
    ]),
    investmentReviewDate () {
      return '투심일시: ' + this.investmentReportMinutes.reports.investment_review_date
    },
    investmentEntityName () {
      return '투심업체명: ' + this.drilldownInvestment.entity_name
    },
    reportTitle () {
      return '본 건 투자의 심의 결과가 상기와 같음을 확인합니다.'
    },
    conversionUnitPrice () {
      if (this.investmentReportMinutes.reports.conversion_unit_price) {
        if (this.investmentReportMinutes.reports.conversion_unit_price.split('\n') > 0) {
          return this.investmentReportMinutes.reports.conversion_unit_price.split('\n').join('<br />')
        }
        else {
          return this.investmentReportMinutes.reports.conversion_unit_price
        }
      }
      else {
        return ''
      }
    },
    sharesConversion () {
      if (this.investmentReportMinutes.reports.shares_conversion) {
        if (this.investmentReportMinutes.reports.shares_conversion.indexOf('\n') > 0) {
          return this.investmentReportMinutes.reports.shares_conversion.indexOf('\n').join('<br />')
        }
        else {
          return this.investmentReportMinutes.reports.shares_conversion
        }
      }
      else {
        return ''
      }
    },
    isQuestionAssent () {
      return this.investmentReportMinutes.reports.question_assent ?
              this.investmentReportMinutes.reports.question_assent.length > 0 :
              false
    },
    bondDetail () {
      const assetClass = this.investmentReportMinutes.reports.details ?
                          this.investmentReportMinutes.reports.details.find(detail => ['CB', 'EB', 'BW'].includes(detail.asset_class)) :
                          {}
      return assetClass ? assetClass : ''
    },
    cpsDetail () {
      const assetClass = this.investmentReportMinutes.reports.details ?
                          this.investmentReportMinutes.reports.details.find(detail => ['CPS', 'RCPS'].includes(detail.asset_class)) :
                          {}
      return assetClass ? assetClass : ''
    },
    cpsIssueDate () {
      return this.cpsDetail.issue_date ? dayjs(this.cpsDetail.issue_date).format('YYYY년 MM월 DD일') : ''
    },
    couponAndYtm () {
      const couponRate = this.bondDetail.coupon_rate ? this.bondDetail.coupon_rate : 0
      const ytm = this.bondDetail.ytm ? this.bondDetail.ytm : 0
      return `${couponRate} % / ${ytm} %`
    },
    preferredDividend () {
      const preferred_dividend = this.cpsDetail.preferred_dividend ? this.cpsDetail.preferred_dividend : 0
      return `${preferred_dividend} %`
    }
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentData',
      'getInvestmentReportMinutes',
    ]),
    selectedStyle(column_type) {
      if (column_type === 'text') {
        return 'border border-black whitespace-pre-line text-left'
      }
      else {
        return 'border border-black whitespace-pre-line text-center'
      }
    },
  },
  mounted () {
    this.getInvestmentData(this.investment_id).then(() => {
      this.getInvestmentReportMinutes(this.investment_id)
    })
  }
}
</script>

<style lang='scss' scoped>

@media print {
  @page {
    margin: 1cm;
  }
}
</style>
