<template>
  <transition name='modal'>
    <div class='modal-mask'>
      <div class='modal-wrapper'>
        <div class='modal-container' :style='modalStyle'>
          <div class='modal-header text-right'>
            <slot name='header'>
              <button @click='doneEditing' class='border rounded-md font-bold hover:shadow-md'>X</button>
            </slot>
          </div>
          <component :is='modalComponentName' @done-editing='doneEditing' :dataRec='dataRec' />
          <div class='modal-footer text-left py-4'>
            <slot name='header'>
              <button @click='doneEditing' class='border rounded-md font-bold hover:shadow-md'>X</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ListingSubmit   from '@/views/investment_ledger/ListingSubmit.vue'
import FundListingEdit from '@/views/investment_ledger/FundListingEdit.vue'
import OrderAdd        from '@/views/investment_ledger/OrderAdd.vue'

export default {
  name: 'LedgerModal',
  components: {
    ListingSubmit,
    FundListingEdit,
    OrderAdd,
  },
  props: [
    'modalName',
    'modalStyle',
    'dataRec',
  ],
  computed: {
    modalComponentName () {
      switch (this.modalName) {
        case 'listing':
          return 'listing-submit'
        case 'fundListing':
          return 'fund-listing-edit'
        case 'order':
          return 'order-add'
        default:
          return 'listing-submit'
      }
    },
  },
  methods: {
    doneEditing () {
      this.$emit('done-editing')
    },
  },
}
</script>

<style lang='scss' scoped>
.modal-mask {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  overflow: auto;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
