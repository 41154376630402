<template>
  <div class='pb-8 px-2 lg:px-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>뉴딜관리</h1>
      </div>
    </portal>
    <div class='flex flex-row my-6'>
      <button
        class='border rounded-md border-gray-50 py-2 px-3 text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='update-venture-data-form-btn'
        @click='editData("add")'>
        <plus-circle-icon class='inline-block' />
        Add
      </button>
    </div>
    <div class='overflow-auto'>
      <table class='text-sm table-fixed'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b'>
            <table-header-filter-text
              v-bind:filter-text.sync='filterItemCode'
              defaultLabel='Item Code'
              class='px-2 py-2 text-left w-36' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterItemName'
              defaultLabel='Item Name'
              class='px-2 py-2 text-left w-36' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterNewdealType'
              defaultLabel='New Deal Type'
              class='px-2 py-2 text-left w-36' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterThemeName'
              defaultLabel='Theme Name'
              class='px-2 py-2 text-left w-36' />
            <table-header-filter-text
              v-bind:filter-text.sync='filterFieldName'
              defaultLabel='Field Name'
              class='px-2 py-2 text-left w-36' />
          </tr>
        </thead>
        <tbody>
          <tr v-for='ventureIndustry in filteredData'
            @click='clickData(ventureIndustry)'
            @dblclick='editData("update")'
            :class='highlightRow(ventureIndustry.item_code)'
            :key='`venture-industry-${ventureIndustry.item_code}`'>
            <td class='px-2 py-2 text-left'>{{ ventureIndustry.item_code }}</td>
            <td class='px-2 py-2 text-left'>{{ ventureIndustry.item_name }}</td>
            <td class='px-2 py-2 text-left'>{{ ventureIndustry.newdeal_type }}</td>
            <td class='px-2 py-2 text-left'>{{ ventureIndustry.theme_name }}</td>
            <td class='px-2 py-2 text-left'>{{ ventureIndustry.field_name }}</td>
          </tr>
          <tr v-if='!filteredData.length'>
            <td colspan=''>
              <div class='text-center text-gray-500 py-4 lg:py-16 mt-2 bg-gray-100'>
                No industries. Please update filters or add.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    <portal to='modals'>
      <venture-edit
        class='px-16'
        :target='target'
        :mode='editMode'
        @done-editing='doneEdit' />
    </portal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState }    from 'vuex'
import StringHelpers               from '@/utils/string-helpers'
import TableHeaderFilterText       from '@/components/TableHeaderFilterText.vue'
import VentureEdit                 from '@/views/investment_ledger/VentureEdit.vue'
import { PlusCircleIcon }          from '@vue-hero-icons/outline'

export default {
  name: 'VentureIndustries',
  components: {
    TableHeaderFilterText,
    VentureEdit,
    PlusCircleIcon,
  },
  data () {
    return {
      target: { item_code: '', item_name: '', newdeal_type: '', theme_name: '', field_name: '' },
      editMode: 'None',
      filterItemCode: '',
      filterItemName: '',
      filterNewdealType: '',
      filterThemeName: '',
      filterFieldName: '',
    }
  },
  computed: {
    ...mapState('entities', [
      'ventureIndustries',
    ]),
    filteredData () {
      if (this.filterItemCode === '' &&
        this.filterItemName === '' &&
        this.filterNewdealType === '' &&
        this.filterThemeName === '' &&
        this.filterFieldName === '') {
        return this.ventureIndustries
      } else {
        return this.ventureIndustries.filter(data =>
          (this.filterItemCode === ''    || StringHelpers.stringIncludesInsensitive(data.item_code, this.filterItemCode)) &&
          (this.filterItemName === ''    || StringHelpers.stringIncludesInsensitive(data.item_name, this.filterItemName)) &&
          (this.filterNewdealType === '' || StringHelpers.stringIncludesInsensitive(data.newdeal_type, this.filterNewdealType)) &&
          (this.filterThemeName === ''   || StringHelpers.stringIncludesInsensitive(data.theme_name, this.filterThemeName)) &&
          (this.filterFieldName === ''   || StringHelpers.stringIncludesInsensitive(data.field_name, this.filterFieldName))
        )
      }
    },
  },
  methods: {
    ...mapActions('entities', [
      'getVentureIndustries',
    ]),
    clickData (data) {
      this.target = data
    },
    editData (mode) {
      if (mode === 'add') {
        this.target = { item_code: '', item_name: '', newdeal_type: '', theme_name: '', field_name: '' }
      }
      this.editMode = mode
      if (this.editMode !== 'None') {
        this.$store.dispatch('modalOpen', {height: 40, width: 60})
      }
    },
    doneEdit (resp) {
      this.$store.dispatch('modalClose')
      this.editMode = 'None'
      if (resp !== '') {
        alert(resp)
        this.getVentureIndustries({})
      }
    },
    highlightRow (code) {
      return code === this.target.item_code ? 'bg-blue-100 border-b' : 'border-b'
    }
  },
  mounted () {
    this.getVentureIndustries({})
  },
}
</script>
